import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

// Componentes
import AdminNav from "../../../components/navs/AdminNav";
import ChoferesTable from "../../../components/tables/ChoferesTable";
import ChoferCreate from "./ChoferCreate";
import ChoferDetail from "./ChoferDetail";
import Loading from "../../../components/others/loading";

// Funciones
import {
  getChoferes,
  createChofer,
  updateChofer,
} from "../../../functions/api/choferes";

const initialState = {
  nombre_completo: "",
  rut: "",
  tipo_licencia: "",
  vencimiento_licencia: "",
  fecha_nacimiento: "",
  activo: true,
};

const ChoferesList = () => {
  const [valuesUpdate, setValuesUpdate] = useState([]);
  const [values, setValues] = useState(initialState);
  const [choferes, setChoferes] = useState([]);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const id = useRef();

  let { user } = useSelector((state) => ({ ...state }));

  useEffect(() => {
    loadChoferes();
  }, []);

  const loadChoferes = () =>
    getChoferes(user.token, page, perPage)
      .then((res) => setChoferes(res.data))
      .catch((err) => console.log(err));

  const handleModal = (e) => {
    e.preventDefault(e);
    id.current = 1;
    if (modal === false) {
      setLoading(true);
      id.current = e.target.value;
      let filteredData = choferes.filter((d) => d.id === Number(id.current));
      setValuesUpdate(filteredData[0]);
      setLoading(false);
    } else {
      setValuesUpdate([]);
    }
    setModal(!modal);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    if (values.rut === "" || values.nombre_completo === "") {
      toast.error("Ingrese rut y nombre de conductor");
      setLoading(false);
    } else {
      createChofer(user.token, values)
        .then((res) => {
          toast.success("Conductor creado exitosamente");
          loadChoferes();
          setValues(initialState);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.response.data.detail);
        });
    }
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleChangeUpdate = (e) => {
    setValuesUpdate({ ...valuesUpdate, [e.target.name]: e.target.value });
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    let data = {};
    data.nombre_completo = valuesUpdate.nombre_completo;
    data.rut = valuesUpdate.rut;
    data.tipo_licencia = valuesUpdate.tipo_licencia;
    data.vencimiento_licencia = valuesUpdate.vencimiento_licencia;
    data.fecha_nacimiento = valuesUpdate.fecha_nacimiento;
    data.activo = true;

    setLoading(true);
    updateChofer(user.token, Number(id.current), data)
      .then((res) => {
        toast.success("Conductor actualizado exitosamente");
        loadChoferes();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    setValuesUpdate([]);
    setModal(!modal);
  };

  const handleDelete = (e) => {
    e.preventDefault();

    let data = {};
    data.nombre_completo = valuesUpdate.nombre_completo;
    data.rut = valuesUpdate.rut;
    data.tipo_licencia = valuesUpdate.tipo_licencia;
    data.vencimiento_licencia = valuesUpdate.vencimiento_licencia;
    data.fecha_nacimiento = valuesUpdate.fecha_nacimiento;
    data.activo = false;

    setLoading(true);
    updateChofer(user.token, Number(id.current), data)
      .then((res) => {
        toast.error("Conductor eliminado exitosamente");
        loadChoferes();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    setValuesUpdate([]);
    setModal(!modal);
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        modal &&
        valuesUpdate &&
        valuesUpdate.length !== 0 && (
          <ChoferDetail
            handleModal={handleModal}
            valuesUpdate={valuesUpdate}
            handleChangeUpdate={handleChangeUpdate}
            handleUpdate={handleUpdate}
            handleDelete={handleDelete}
          />
        )
      )}
      <AdminNav />
      <div className="subNav">
        <span>Admin / Conductores</span>
      </div>
      <div className="contenedorTm">
        <div className="form-container">
          <ChoferCreate
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            values={values}
          />
        </div>
        <div className="table-title">
          <h6>CONDUCTORES</h6>
        </div>
        <div className="table-container">
          <ChoferesTable values={choferes} handleModal={handleModal} />
        </div>
      </div>
    </div>
  );
};

export default ChoferesList;
