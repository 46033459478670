import axios from "axios";




export const getClientes = async(token,page,per_page) =>{
    return await axios.get(`${process.env.REACT_APP_API}/users/clientes?page=${page}&per_page=${per_page}`,{
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
}



export const createCliente = async(token,body) =>{
    return await axios.post(`${process.env.REACT_APP_API}/users/create_cliente`,body,{
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
}


export const updateCliente = async(token,id,body) =>{
    return await axios.put(`${process.env.REACT_APP_API}/users/update_cliente/${id}`,body,{
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
}
