

export const formatRut = (rut) => {
    const rutLimpio = rut.replace(/[^0-9kK]/g, '');
    const cuerpo = rutLimpio.slice(0, -1);
    const dv = rutLimpio.slice(-1).toUpperCase();
    if (rutLimpio.length < 2) return rutLimpio;
    let cuerpoFormatoMiles = cuerpo
        .toString()
        .split('')
        .reverse()
        .join('')
        .replace(/(?=\d*\.?)(\d{3})/g, '$1.');
    cuerpoFormatoMiles = cuerpoFormatoMiles
        .split('')
        .reverse()
        .join('')
        .replace(/^[\.]/, '');
    return `${cuerpoFormatoMiles}-${dv}`;

}


export const formatNroContainer = (input) => {
    console.log(input)
    let inputLimpio = input.replace(/[^a-zA-Z0-9]/g, '')
    // Tomamos los primeros 4 caracteres y nos aseguramos que sean letras
    let letras = inputLimpio.slice(0, 4).replace(/[^a-zA-Z]/g, '').toUpperCase()

    // Tomamos los siguientes 7 caracteres y nos aseguramos que sean números
    let numeros = inputLimpio.slice(4, 12).replace(/[^0-9]/g, '');

    let ultimodigito = numeros.slice(-1) 

    if (numeros.length < 7) {
        return `${letras}${numeros}`
    }
    else {
        numeros = numeros.slice(0,6)
        return `${letras}${numeros}-${ultimodigito}`
    }


    
}


export function standardizeDateFormat(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // +1 because months are 0-indexed
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
}
