import React from 'react'

const TipoContenedorDetail = ({ handleModal, handleUpdate, handleChangeUpdate, valuesUpdate, handleDelete, isChecked, setIsChecked }) => {

    const { dimension, descripcion, refeer, conexion, carga_peligrosa } = valuesUpdate

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    return (
        <>
            <div className='modalBackground' onClick={handleModal}>
            </div>
            <div className='modalCard'>
                <div className="row">
                    <div className="col-md-10">
                        <p className='modalTitle'>CREAR TIPO CONTENEDOR</p>
                    </div>

                    <div className='col-md-2'>
                        <button className='btn btn-danger btn-sm' onClick={handleDelete}>
                            Eliminar
                        </button>
                    </div>


                    <div className="col-md-5 mt-2 ">
                        <label className='label-title'>Dimensión</label>
                        <br />
                        <input className='loginInput' value={dimension} name='dimension' onChange={handleChangeUpdate} />

                    </div>
                    <div className="col-md-7 mt-2 ">
                        <label className='label-title'>Descripción</label>
                        <br />
                        <input className='loginInput' value={descripcion} name='descripcion' onChange={handleChangeUpdate} />

                    </div>

                    <div className='col-md-12 mt-3'>
                        <button onClick={handleUpdate} className='btn-blue'> <i class="fa-regular fa-floppy-disk"></i> Guardar</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TipoContenedorDetail