import axios from "axios";

export const getSubClientes = async(token,page,per_page) =>{
    return await axios.get(`${process.env.REACT_APP_API}/users/subclientes?page=${page}&per_page=${per_page}`,{
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
}



export const createSubCliente = async(token,body) =>{
    return await axios.post(`${process.env.REACT_APP_API}/users/create_subcliente`,body,{
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
}


export const updateSubCliente = async(token,id,body) =>{
    return await axios.put(`${process.env.REACT_APP_API}/users/update_subcliente/${id}`,body,{
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
}