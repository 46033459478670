import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'


// Componentes
import AdminNav from '../../components/navs/AdminNav'
import Loading from '../../components/others/loading'
import MovimientosTableAdmin from '../../components/tables/MovimientosTableAdmin'

// Funciones
import { getMovimientos, updateMovimientos } from '../../functions/api/movimientos'

const ResumenMovimientoAdmin = () => {

  const [values, setValues] = useState([])
  const [valuesUpdate, setValuesUpdate] = useState([])
  const [movimientos, setMovimientos] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [modal, setModal] = useState(false)
  const [perPage, setPerPage] = useState(100)
  const id = useRef()

  let { user } = useSelector((state) => ({ ...state }))

  let navigate = useNavigate()

  useEffect(() => {

    loadMovimientos()

  }, []);


  const loadMovimientos = () => {
    setLoading(true)
    getMovimientos(user.token, page, perPage)
      .then(res => {
        setValues(res.data.data)
        setLoading(false)
      })
      .catch(err => {

        console.log(err)
        setLoading(false)
      })
  }




  const handleModal = (e) => {
    e.preventDefault(e)
    id.current = 1
    if (modal === false) {
      setLoading(true)
      id.current = e.target.value
      let filteredData = values.filter(d => d.id === Number(id.current));
      setValuesUpdate(filteredData[0])
      setLoading(false)
    } else {
      setValuesUpdate([])
    }
    setModal(!modal)
  }

  return (
    loading ? (
    <Loading />
 ) : (
    <div >
      <AdminNav />
      <div className="m-5">
        <MovimientosTableAdmin
          loading={loading}
          values={values}
          handleModal={handleModal}
        />
      </div>
    </div>
  ))
}

export default ResumenMovimientoAdmin