import React, {
  useCallback,
  useMemo,
  useState,
  useRef,
  useEffect,
} from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  Spinner,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  TableCell,
  Pagination,
  Chip,
  Input,
  Switch,
  Select,
  SelectItem,
  Accordion,
  AccordionItem,
} from "@nextui-org/react";

import Loading from "../others/loading";
import { toast } from "react-toastify";

import { DeleteIcon } from "../../img/DeleteIcon";
import { EditIcon } from "../../img/EditIcon";
import { EyeIcon } from "../../img/EyeIcon";

import { updateMovimientos } from "../../functions/api/movimientos";

import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { standardizeDateFormat } from "../../functions/utilities/format";

const filtersInitialState = {
  numero_contenedor: "",
  numero_guia: "",
  fecha_recepcion_desde: "",
  fecha_recepcion_hasta: "",
};

const MovimientosTable = ({
  values,
  almacenes,
  choferes,
  clientes,
  subclientes,
  tipoContenedor,
  flota,
  token,
  setLoading,
  isSelected,
  setIsSelected,
  loading,
}) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [openEdit, setOpenEdit] = useState(false);
  const [openEye, setOpenEye] = useState(false);

  const [filterValue, setFilterValue] = useState(filtersInitialState);

  const columns = [
    {
      label: "ID",
      key: "id",
    },
    {
      label: "Recepción",
      key: "fecha_recepcion",
    },
    {
      label: "Hora Recepción",
      key: "hora_recepcion",
    },
    {
      label: "Fecha Despacho",
      key: "fecha_salida",
    },
    {
      label: "Nª Contenedor",
      key: "numero_contenedor",
    },
    {
      label: "Cliente",
      key: "razon_social_cliente",
    },
    {
      label: "Nª Guía",
      key: "numero_guia",
    },
    {
      label: "Tipo Movimiento",
      key: "descripcion_estado",
    },
    {
      label: "Acciones",
      key: "actions",
    },
  ];

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const filteredItems = useMemo(() => {
    let filterValues = [...values];

    if (filterValue.numero_contenedor !== "") {
      filterValues = filterValues.filter((c) =>
        c.numero_contenedor
          .toUpperCase()
          .includes(filterValue.numero_contenedor.toUpperCase())
      );
    }

    if (filterValue.numero_guia !== "") {
      filterValues = filterValues.filter((c) =>
        c.numero_guia
          .toUpperCase()
          .includes(filterValue.numero_guia.toUpperCase())
      );
    }

    // filtrar desde hasta fecha
    if (
      filterValue.fecha_recepcion_desde !== "" &&
      filterValue.fecha_recepcion_hasta !== ""
    ) {
      filterValues = filterValues.filter((item) => {
        const itemDate = new Date(item.fecha_recepcion);
        const startDate = new Date(filterValue.fecha_recepcion_desde);
        const endDate = new Date(filterValue.fecha_recepcion_hasta);
        return itemDate >= startDate && itemDate <= endDate;
      });
    } else if (
      filterValue.fecha_recepcion_desde !== "" &&
      filterValue.fecha_recepcion_hasta === ""
    ) {
      filterValues = filterValues.filter((item) => {
        const itemDate = new Date(item.fecha_recepcion);
        const startDate = new Date(filterValue.fecha_recepcion_desde);
        return itemDate >= startDate;
      });
    } else if (
      filterValue.fecha_recepcion_desde === "" &&
      filterValue.fecha_recepcion_hasta !== ""
    ) {
      filterValues = filterValues.filter((item) => {
        const itemDate = new Date(item.fecha_recepcion);
        const startDate = new Date(filterValue.fecha_recepcion_desde);
        const endDate = new Date(filterValue.fecha_recepcion_hasta);
        return itemDate <= endDate;
      });
    }

    return filterValues;
  }, [values, filterValue]);

  const items = useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;

    return filteredItems.slice(start, end);
  }, [page, filteredItems]);

  const onRowsPerPageChange = useCallback((e) => {
    setRowsPerPage(Number(e.target.value));
    setPage(1);
  }, []);

  const detailMov = useRef([]);

  const [updateData, setUpdateData] = useState([]);

  const onOpenEdit = (value) => {
    console.log(value);

    if (openEdit === true) {
      setOpenEdit(!openEdit);
      // setValuesUpdate([])
    } else {
      setIsSelected(value.refeer);

      setUpdateData({
        movimiento_id: Number(value.id),
        user_id: value.user_id,
        new_numero_contenedor: value.numero_contenedor,
        new_numero_sello: value.numero_sello,
        new_numero_guia: value.numero_guia,
        new_activo: value.activo,
        new_chofer_id_entrada: value.chofer_id_entrada,
        new_chofer_id_salida: value.chofer_id_salida,
        new_cliente_id: value.cliente_id,
        new_tipo_contenedor_id: value.tipo_contenedor_id,
        new_estado: value.estado,
        new_almacen_id: value.almacen_id,
        new_camion_id_entrada: value.camion_id_entrada,
        new_camion_id_salida: value.camion_id_salida,
        new_refeer: value.refeer,
        new_conexion: value.conexion,
        new_carga_peligrosa: value.carga_peligrosa,
        recep: value.fecha_recepcion,
        new_subcliente_id: value.subcliente_id,
        new_fecha_recepcion: value.fecha_recepcion,
        new_hora_recepcion: value.hora_recepcion,
        new_fecha_salida: value.fecha_salida,
        new_hora_salida: value.hora_salida,
      });
      setOpenEdit(!openEdit);
    }
    console.log(updateData);
  };

  const onOpenEye = (value) => {
    console.log(value);

    if (openEye === true) {
      setOpenEye(!openEye);
    } else {
      setOpenEye(!openEye);
      detailMov.current = value;
    }
    console.log(detailMov);
  };

  const tipoMovColorMap = {
    STOCK: "success",
    DESPACHADO: "warning",
  };

  const handleUpdate = () => {
    updateData.new_refeer = isSelected;
    updateData.new_subcliente_id = Number(updateData.new_subcliente_id);
    console.log(updateData);

    if (updateData.new_subcliente_id === 0) updateData.new_subcliente_id = null;

    if (new_fecha_ingreso !== undefined) {
      console.log(new_fecha_ingreso);
      var currentDate = new Date(new_fecha_ingreso);

      var year = currentDate.getFullYear();
      var month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 to month since it's zero-based
      var day = currentDate.getDate().toString().padStart(2, "0");

      // Get the current hour
      var currentHour = currentDate.getHours().toString().padStart(2, "0");
      var currentMinutes = currentDate.getMinutes().toString().padStart(2, "0");

      var formattedDate = year + "-" + month + "-" + day;

      updateData.new_fecha_recepcion = formattedDate;
      updateData.new_hora_recepcion =
        currentHour + ":" + currentMinutes + ":00";
    }

    if (new_fecha_egreso !== undefined) {
      console.log(new_fecha_egreso);
      var currentDate = new Date(new_fecha_egreso);

      var year = currentDate.getFullYear();
      var month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 to month since it's zero-based
      var day = currentDate.getDate().toString().padStart(2, "0");

      // Get the current hour
      var currentHour = currentDate.getHours().toString().padStart(2, "0");
      var currentMinutes = currentDate.getMinutes().toString().padStart(2, "0");

      var formattedDate = year + "-" + month + "-" + day;

      updateData.new_fecha_salida = formattedDate;
      updateData.new_hora_salida = currentHour + ":" + currentMinutes + ":00";
    }

    setLoading(true);
    updateMovimientos(token, updateData, updateData.movimiento_id)
      .then((res) => {
        toast.success("Solicitud de modificación enviada");
        setOpenEdit(!openEdit);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const renderCell = useCallback((user, columnKey) => {
    const cellValue = user[columnKey];

    switch (columnKey) {
      case "descripcion_estado":
        return (
          <Chip
            className="capitalize "
            color={tipoMovColorMap[user.descripcion_estado]}
            size="sm"
            variant="flat"
          >
            {cellValue}
          </Chip>
        );
      case "actions":
        return (
          <div className="relative flex items-center gap-2">
            <Button
              isIconOnly
              onClick={() => onOpenEye(user)}
              className="text-lg w-1 h-5 bg-transparent text-slate-500 cursor-pointer active:opacity-50"
              aria-label="Like"
            >
              <EyeIcon />
            </Button>
            <Button
              isIconOnly
              onClick={() => onOpenEdit(user)}
              className="text-lg w-1 h-5 bg-transparent text-lime-500 cursor-pointer active:opacity-50"
              aria-label="Like"
            >
              <EditIcon />
            </Button>
          </div>
        );
      default:
        return cellValue;
    }
  }, []);

  const handleChangeFilter = (e) => {
    console.log(e.target.value);
    setFilterValue({ ...filterValue, [e.target.name]: e.target.value });
  };

  const onClear1 = () => {
    setFilterValue({ ...filterValue, numero_contenedor: "" });
  };

  const onClear2 = () => {
    setFilterValue({ ...filterValue, numero_guia: "" });
  };

  const onClear3 = () => {
    setFilterValue({ ...filterValue, fecha_recepcion_desde: "" });
  };

  const onClear4 = () => {
    setFilterValue({ ...filterValue, fecha_recepcion_hasta: "" });
  };

  const topContent = useMemo(() => {
    return (
      <div className="grid gap-4 grid-cols-4">
        {/* {JSON.stringify(filterValue)} */}
        <Input
          isClearable
          placeholder="Buscar contenedor"
          size="sm"
          startContent={<MagnifyingGlassIcon className="h-6" />}
          name="numero_contenedor"
          value={filterValue.numero_contenedor}
          onClear={() => onClear1()}
          onChange={handleChangeFilter}
        />

        <Input
          isClearable
          placeholder="Buscar Nº guia"
          size="sm"
          startContent={<MagnifyingGlassIcon className="h-6" />}
          name="numero_guia"
          value={filterValue.numero_guia}
          onClear={() => onClear2()}
          onChange={handleChangeFilter}
        />

        <Input
          isClearable
          type="date"
          size="sm"
          label="Fecha Desde"
          labelPlacement="outside-left"
          name="fecha_recepcion_desde"
          value={filterValue.fecha_recepcion_desde}
          onClear={() => onClear3()}
          onChange={handleChangeFilter}
        />

        <Input
          isClearable
          type="date"
          size="sm"
          label="Fecha Hasta"
          labelPlacement="outside-left"
          name="fecha_recepcion_hasta"
          value={filterValue.fecha_recepcion_hasta}
          onClear={() => onClear4()}
          onChange={handleChangeFilter}
        />
      </div>
    );
  }, [filterValue, handleChangeFilter, onClear1, onClear2, onClear3, onClear4]);

  const pages = Math.ceil(filteredItems.length / rowsPerPage);

  const {
    movimiento_id,
    user_id,
    new_numero_contenedor,
    new_numero_sello,
    new_numero_guia,
    new_activo,
    new_fecha_egreso,
    new_fecha_ingreso,
    new_chofer_id_entrada,
    new_chofer_id_salida,
    new_cliente_id,
    new_tipo_contenedor_id,
    new_estado,
    new_almacen_id,
    new_camion_id_entrada,
    new_subcliente_id,
    new_camion_id_salida,
    new_refeer,
    new_carga_peligrosa,
    new_conexion,
    recep,
  } = updateData;

  const handleChange = (e) => {
    setUpdateData({ ...updateData, [e.target.name]: e.target.value });
  };

  const subclientesFiltered =
    subclientes &&
    subclientes.filter((d) => d.cliente_id === Number(new_cliente_id));

  return pages ? (
    <>
      <Modal
        scrollBehavior="inside"
        size="5xl"
        isOpen={openEye}
        onOpenChange={onOpenEye}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                <div className="flex flex-col">
                  <p className="text-md">
                    Movimiento Nº {detailMov.current.id} -{" "}
                    {detailMov.current.descripcion_estado} -{" "}
                    <Chip className="bg-indigo-500 text-white">
                      {" "}
                      Ingresado el {detailMov.current.fecha_recepcion}
                    </Chip>
                  </p>
                </div>
              </ModalHeader>
              <ModalBody>
                <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-10  pt-4">
                  <div className="flex w-full col-span-1 flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                    <Button className="w-full" color="primary">
                      Cliente
                    </Button>
                  </div>
                  <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                    <Input
                      type="text"
                      label="RUT"
                      labelPlacement="outside"
                      value={detailMov.current.rut_cliente}
                      variant="bordered"
                      name="numero_contenedor"
                    />
                  </div>
                  <div className="flex w-full col-span-1 flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                    <Input
                      type="text"
                      label="Razón Social"
                      labelPlacement="outside"
                      value={detailMov.current.razon_social_cliente}
                      variant="bordered"
                      name="numero_contenedor"
                    />
                  </div>
                  <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                    <Input
                      type="text"
                      label="Dirección"
                      labelPlacement="outside"
                      value={detailMov.current.direccion_cliente}
                      variant="bordered"
                      name="numero_contenedor"
                    />
                  </div>
                  {detailMov.current.razon_social_subcliente !== null && (
                    <>
                      <div className="flex w-full col-span-1 flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                        <Button className="w-full" color="primary">
                          SubCliente
                        </Button>
                      </div>
                      <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                        <Input
                          type="text"
                          label="RUT"
                          labelPlacement="outside"
                          value={detailMov.current.rut_subcliente}
                          variant="bordered"
                          name="numero_contenedor"
                        />
                      </div>
                      <div className="flex w-full col-span-1 flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                        <Input
                          type="text"
                          label="Razón Social"
                          labelPlacement="outside"
                          value={detailMov.current.razon_social_subcliente}
                          variant="bordered"
                          name="numero_contenedor"
                        />
                      </div>
                      <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                        <Input
                          type="text"
                          label="Dirección "
                          labelPlacement="outside"
                          value={detailMov.current.direccion_subcliente}
                          variant="bordered"
                          name="numero_contenedor"
                        />
                      </div>
                    </>
                  )}
                  <div className="flex w-full col-span-4 flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                    <hr />
                  </div>
                  <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                    <Input
                      type="text"
                      label="Nª de Contenedor"
                      labelPlacement="outside"
                      value={detailMov.current.numero_contenedor}
                      variant="bordered"
                      name="numero_contenedor"
                    />
                  </div>
                  <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                    <Input
                      type="text"
                      label="Tipo Contenedor"
                      labelPlacement="outside"
                      value={detailMov.current.dimension}
                      variant="bordered"
                      name="numero_contenedor"
                    />
                  </div>
                  <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                    <Input
                      type="text"
                      label="Contenedor"
                      labelPlacement="outside"
                      value={detailMov.current.descripcion_tipo_contenedor}
                      variant="bordered"
                      name="numero_contenedor"
                    />
                  </div>
                  <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                    <Input
                      type="text"
                      label="Conectado"
                      labelPlacement="outside"
                      value={detailMov.current.conexion}
                      variant="bordered"
                      name="numero_contenedor"
                    />
                  </div>

                  <div className="flex  w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                    <Input
                      type="text"
                      label="Nª de Sello"
                      labelPlacement="outside"
                      value={detailMov.current.numero_sello}
                      variant="bordered"
                      name="numero_contenedor"
                    />
                  </div>

                  <div className="flex w-full  flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                    <Input
                      type="text"
                      label="Nª de Guía"
                      labelPlacement="outside"
                      value={detailMov.current.numero_guia}
                      variant="bordered"
                      name="numero_contenedor"
                    />
                  </div>

                  <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                    <Switch
                      defaultSelected
                      isSelected={detailMov.current.refeer}
                    >
                      Refeer
                    </Switch>
                  </div>
                  <div className="flex w-full  flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                    <Switch
                      defaultSelected
                      isSelected={detailMov.current.carga_peligrosa}
                    >
                      Carga Peligrosa
                    </Switch>
                  </div>
                  <div className="flex w-full col-span-4 flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                    <hr />
                  </div>
                  {detailMov.current.estado === "STOCK" ? (
                    <>
                      <div className="flex w-full  flex-wrap items-end  col-span-2 md:flex-nowrap mb-6 md:mb-0 gap-4">
                        <Input
                          type="text"
                          label="Conductor Ingreso"
                          labelPlacement="outside"
                          value={detailMov.current.nombre_conductor_entrada}
                          variant="bordered"
                          name="numero_contenedor"
                        />
                      </div>
                      <div className="flex w-full  flex-wrap  col-span-2 items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                        <Input
                          type="text"
                          label="Placa Patente Ingreso"
                          labelPlacement="outside"
                          value={detailMov.current.placa_patente_entrada}
                          variant="bordered"
                          name="numero_contenedor"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex w-full  flex-wrap  items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                        <Input
                          type="text"
                          label="Conductor Ingreso"
                          labelPlacement="outside"
                          value={detailMov.current.nombre_conductor_entrada}
                          variant="bordered"
                          name="numero_contenedor"
                        />
                      </div>
                      <div className="flex w-full  flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                        <Input
                          type="text"
                          label="Placa Patente Ingreso"
                          labelPlacement="outside"
                          value={detailMov.current.placa_patente_entrada}
                          variant="bordered"
                          name="numero_contenedor"
                        />
                      </div>
                      <div className="flex w-full  flex-wrap  items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                        <Input
                          type="text"
                          label="Conductor Salida"
                          labelPlacement="outside"
                          value={detailMov.current.nombre_conductor_salida}
                          variant="bordered"
                          name="numero_contenedor"
                        />
                      </div>
                      <div className="flex w-full  flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                        <Input
                          type="text"
                          label="Placa Patente Salida"
                          labelPlacement="outside"
                          value={detailMov.current.placa_patente_salida}
                          variant="bordered"
                          name="numero_contenedor"
                        />
                      </div>
                    </>
                  )}
                </div>
              </ModalBody>
              <ModalFooter>
                <Button className="w-full" color="danger" onPress={onOpenEye}>
                  Cerrar
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      <Modal
        scrollBehavior="outside"
        size="5xl"
        isOpen={openEdit}
        onOpenChange={onOpenEdit}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                <div className="flex flex-col">
                  <p className="text-md">Movimiento Nº {movimiento_id} </p>
                  <Chip className="bg-indigo-500 text-white">
                    Ingresado el {recep}
                  </Chip>
                </div>
              </ModalHeader>
              <ModalBody>
                {loading ? (
                  <Spinner />
                ) : (
                  <>
                    <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-10 mt-2">
                      <div className="flex flex-col">
                        <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                          <Input
                            type="datetime-local"
                            label="Fecha Recepción"
                            labelPlacement="outside"
                            placeholder="Fecha recepción"
                            value={new_fecha_ingreso}
                            variant="bordered"
                            // className='uppercase'
                            name="new_fecha_ingreso"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                          <Input
                            type="datetime-local"
                            label="Fecha Salida"
                            labelPlacement="outside"
                            placeholder="Fecha salida"
                            value={new_fecha_egreso}
                            variant="bordered"
                            // className='uppercase'
                            name="new_fecha_egreso"
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="flex flex-col">
                        <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                          <Select
                            items={clientes}
                            label="Cliente"
                            labelPlacement="outside"
                            placeholder="Seleccionar Cliente"
                            variant="bordered"
                            defaultSelectedKeys={[`${new_cliente_id}`]}
                            value={new_cliente_id}
                            name="new_cliente_id"
                            onChange={handleChange}
                          >
                            {(clientes) => (
                              <SelectItem key={clientes.id}>
                                {clientes.razon_social}
                              </SelectItem>
                            )}
                          </Select>
                        </div>
                      </div>

                      <div className="flex flex-col">
                        <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                          <Select
                            items={subclientesFiltered}
                            label="Subcliente"
                            labelPlacement="outside"
                            placeholder="Seleccionar Cliente"
                            variant="bordered"
                            defaultSelectedKeys={[`${new_subcliente_id}`]}
                            value={new_subcliente_id}
                            name="new_subcliente_id"
                            onChange={handleChange}
                          >
                            {(items) => (
                              <SelectItem key={items.id}>
                                {items.razon_social}
                              </SelectItem>
                            )}
                          </Select>
                        </div>
                      </div>
                      <div className="flex col-span- w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                        <Input
                          type="text"
                          label="Nª de Sello"
                          labelPlacement="outside"
                          name="new_numero_sello"
                          value={new_numero_sello}
                          onChange={handleChange}
                          variant="bordered"
                        />
                      </div>

                      <div className="flex w-full col-span-flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                        <Input
                          type="text"
                          label="Nª de Guía"
                          labelPlacement="outside"
                          value={new_numero_guia}
                          onChange={handleChange}
                          variant="bordered"
                          name="new_numero_guia"
                        />
                      </div>
                      <div className="flex w-full col-span-flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                        <Input
                          type="text"
                          label="Nª de Contenedor"
                          labelPlacement="outside"
                          value={new_numero_contenedor}
                          onChange={handleChange}
                          variant="bordered"
                          name="new_numero_contenedor"
                        />
                      </div>
                      <div className="flex flex-col">
                        <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                          <Select
                            label="Conexión"
                            labelPlacement="outside"
                            placeholder="Seleccionar Conexión"
                            variant="bordered"
                            defaultSelectedKeys={[`${new_conexion}`]}
                            value={new_conexion}
                            name="new_conexion"
                            onChange={handleChange}
                          >
                            <SelectItem key="conectado">Conectado</SelectItem>
                            <SelectItem key="desconectado">
                              Desconectado
                            </SelectItem>
                          </Select>
                        </div>
                      </div>

                      <div className="flex flex-col">
                        <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                          <Select
                            label="Carga Peligrosa"
                            labelPlacement="outside"
                            placeholder="Seleccionar Carga Peligrosa"
                            variant="bordered"
                            defaultSelectedKeys={[`${new_carga_peligrosa}`]}
                            value={new_carga_peligrosa}
                            name="new_carga_peligrosa"
                            onChange={handleChange}
                          >
                            <SelectItem key="si">Si</SelectItem>
                            <SelectItem key="no">No</SelectItem>
                          </Select>
                        </div>
                      </div>

                      <div className="flex flex-col">
                        <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                          <Select
                            items={almacenes}
                            label="Almacén"
                            labelPlacement="outside"
                            placeholder="Seleccionar Almacén"
                            variant="bordered"
                            defaultSelectedKeys={[`${new_almacen_id}`]}
                            value={new_almacen_id}
                            name="new_almacen_id"
                            onChange={handleChange}
                          >
                            {(items) => (
                              <SelectItem key={items.id}>
                                {items.nombre}
                              </SelectItem>
                            )}
                          </Select>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                          <Select
                            items={tipoContenedor}
                            label="Tipo Contenedor"
                            labelPlacement="outside"
                            placeholder="Seleccionar Tipo Contenedor"
                            variant="bordered"
                            defaultSelectedKeys={[`${new_tipo_contenedor_id}`]}
                            value={new_tipo_contenedor_id}
                            name="new_tipo_contenedor_id"
                            onChange={handleChange}
                          >
                            {(items) => (
                              <SelectItem key={items.id}>
                                {items.descripcion}
                              </SelectItem>
                            )}
                          </Select>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="flex w-full mt-10 flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                          <Switch
                            value={new_refeer}
                            defaultValue={new_refeer}
                            name="new_refeer"
                            isSelected={isSelected}
                            onValueChange={setIsSelected}
                          >
                            Refeer
                          </Switch>
                        </div>
                      </div>

                      {new_estado && new_estado === "STOCK" ? (
                        <>
                          <div className="flex w-full col-span-2 flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                            <Select
                              items={choferes}
                              label="Conductor Ingreso"
                              labelPlacement="outside"
                              placeholder="Seleccionar Conductor"
                              variant="bordered"
                              defaultSelectedKeys={[`${new_chofer_id_entrada}`]}
                              value={new_chofer_id_entrada}
                              name="new_chofer_id_entrada"
                              onChange={handleChange}
                            >
                              {(items) => (
                                <SelectItem key={items.id}>
                                  {items.nombre_completo}
                                </SelectItem>
                              )}
                            </Select>
                          </div>
                          <div className="flex w-full col-span-2 flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                            <Select
                              items={flota}
                              label="Camión Ingreso"
                              labelPlacement="outside"
                              placeholder="Seleccionar Camión"
                              variant="bordered"
                              defaultSelectedKeys={[`${new_camion_id_entrada}`]}
                              value={new_camion_id_entrada}
                              name="new_camion_id_entrada"
                              onChange={handleChange}
                            >
                              {(items) => (
                                <SelectItem key={items.id}>
                                  {items.placa_patente}
                                </SelectItem>
                              )}
                            </Select>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="flex flex-col">
                            <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                              <Select
                                items={choferes}
                                label="Conductor Ingreso"
                                labelPlacement="outside"
                                placeholder="Seleccionar Conductor"
                                variant="bordered"
                                defaultSelectedKeys={[
                                  `${new_chofer_id_entrada}`,
                                ]}
                                value={new_chofer_id_entrada}
                                name="new_chofer_id_entrada"
                                onChange={handleChange}
                              >
                                {(items) => (
                                  <SelectItem key={items.id}>
                                    {items.nombre_completo}
                                  </SelectItem>
                                )}
                              </Select>
                            </div>
                          </div>
                          <div className="flex flex-col">
                            <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                              <Select
                                items={flota}
                                label="Camión Ingreso"
                                labelPlacement="outside"
                                placeholder="Seleccionar Camión"
                                variant="bordered"
                                defaultSelectedKeys={[
                                  `${new_camion_id_entrada}`,
                                ]}
                                value={new_camion_id_entrada}
                                name="new_camion_id_entrada"
                                onChange={handleChange}
                              >
                                {(items) => (
                                  <SelectItem key={items.id}>
                                    {items.placa_patente}
                                  </SelectItem>
                                )}
                              </Select>
                            </div>
                          </div>
                          <div className="flex flex-col">
                            <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                              <Select
                                items={choferes}
                                label="Conductor Salida"
                                labelPlacement="outside"
                                placeholder="Seleccionar Conductor"
                                variant="bordered"
                                defaultSelectedKeys={[
                                  `${new_chofer_id_salida}`,
                                ]}
                                value={new_chofer_id_salida}
                                name="new_chofer_id_salida"
                                onChange={handleChange}
                              >
                                {(items) => (
                                  <SelectItem key={items.id}>
                                    {items.nombre_completo}
                                  </SelectItem>
                                )}
                              </Select>
                            </div>
                          </div>
                          <div className="flex flex-col">
                            <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                              <Select
                                items={flota}
                                label="Camión Salida"
                                labelPlacement="outside"
                                placeholder="Seleccionar Camión"
                                variant="bordered"
                                defaultSelectedKeys={[
                                  `${new_camion_id_salida}`,
                                ]}
                                value={new_camion_id_salida}
                                name="new_camion_id_salida"
                                onChange={handleChange}
                              >
                                {(items) => (
                                  <SelectItem key={items.id}>
                                    {items.placa_patente}
                                  </SelectItem>
                                )}
                              </Select>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onOpenEdit}>
                  Cerrar
                </Button>
                <Button
                  color="success"
                  className="text-white"
                  onClick={handleUpdate}
                >
                  <EditIcon /> Solicitar Modificación
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <Table
        isCompact
        topContent={topContent}
        aria-label="Example table with dynamic content"
        bottomContent={
          <div className="flex w-full justify-between">
            <Pagination
              isCompact
              showControls
              showShadow
              color="secondary"
              page={page}
              total={pages}
              onChange={(page) => setPage(page)}
            />
            <label className="flex items-center text-default-400 text-small">
              Filas por página:
              <select
                className="bg-transparent outline-none text-default-400 text-small"
                onChange={onRowsPerPageChange}
              >
                <option value="20">20</option>
                <option value="30">30</option>
              </select>
            </label>
          </div>
        }
      >
        <TableHeader columns={columns}>
          {(column) => (
            <TableColumn key={column.key}>{column.label}</TableColumn>
          )}
        </TableHeader>
        <TableBody
          isLoading={loading}
          loadingContent={<Spinner label="Loading..." />}
          items={items}
        >
          {(item) => (
            <TableRow key={item.key}>
              {(columnKey) => (
                <TableCell>{renderCell(item, columnKey)}</TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  ) : (
    <Table
      topContent={topContent}
      aria-label="Example table with dynamic content"
      emptyContent={"No hay datos para mostrar."}
      bottomContent={
        <div className="flex w-full justify-center">
          <Pagination
            isCompact
            showControls
            showShadow
            color="secondary"
            page={page}
            total={pages}
            onChange={(page) => setPage(page)}
          />
        </div>
      }
    >
      <TableHeader columns={columns}>
        {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
      </TableHeader>
      <TableBody
        isLoading={loading}
        loadingContent={<Spinner label="Loading..." />}
        items={items}
      >
        {(item) => (
          <TableRow key={item.key}>
            {(columnKey) => (
              <TableCell>{renderCell(item, columnKey)}</TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default MovimientosTable;
