import React from 'react'
import { formatRut } from '../../../functions/utilities/format'

const ClienteCreate = ({ handleSubmit, handleChange, values, handleEditSubClientes, handleAddSubcliente, subClientesList, deleteSubCliente, subClientes }) => {

    const { razon_social, rut, direccion, contacto, email } = values



    return (

        <>
            <div className="row">
                <div className="col-md-12">
                    <p className='modalTitle'>CREAR CLIENTE</p>
                </div>
                <div className="col-md-2 ">
                    <label className='label-title'>Rut</label>
                    <br />
                    <input className='loginInput' value={formatRut(rut)} name='rut' maxLength='12' onChange={handleChange} />
                </div>

                <div className="col-md-3 ">
                    <label className='label-title'>Razón Social</label>
                    <br />
                    <input className='loginInput' value={razon_social} name='razon_social' onChange={handleChange} />
                </div>
                <div className="col-md-2 ">
                    <label className='label-title'>Email</label>
                    <br />
                    <div class="input-container">
                        <input className='loginInput' value={email} name='email' onChange={handleChange} />
                        <i class="fa-solid fa-envelope icon" />
                    </div>
                </div>

                <div className="col-md-3 ">
                    <label className='label-title'>Dirección</label>
                    <br />
                    <input className='loginInput' value={direccion} name='direccion' onChange={handleChange} />
                </div>
                <div className="col-md-2 ">
                    <label className='label-title'>Contacto</label>
                    <br />
                    <div class="input-container">
                        <input className='loginInput' value={contacto} name='contacto' onChange={handleChange} />
                        <i class="icon fa-solid fa-phone" />
                    </div>
                </div>

                <div className='subcliente-card row'>

                    <div className="col-md-12">
                        <p className='modalTitle'>SUBCLIENTES</p>
                    </div>

                    <div className="col-md-2 ">
                        <label className='label-title'>Rut</label>
                        <br />
                        <input className='loginInput' value={formatRut(subClientes.rut)} name='rut' maxLength='12' onChange={handleEditSubClientes} />
                    </div>

                    <div className="col-md-2 ">
                        <label className='label-title'>Razón Social</label>
                        <br />
                        <input className='loginInput' value={subClientes.razon_social} name='razon_social' onChange={handleEditSubClientes} />
                    </div>
                    <div className="col-md-2 ">
                        <label className='label-title'>Email</label>
                        <br />
                        <div class="input-container">
                            <input className='loginInput' value={subClientes.email} name='email' onChange={handleEditSubClientes} />
                            <i class="fa-solid fa-envelope icon" />
                        </div>
                    </div>

                    <div className="col-md-2 ">
                        <label className='label-title'>Dirección</label>
                        <br />
                        <input className='loginInput' value={subClientes.direccion} name='direccion' onChange={handleEditSubClientes} />
                    </div>
                    <div className="col-md-2">
                        <label className='label-title'>Contacto</label>
                        <br />
                        <div class="input-container">
                            <input className='loginInput' value={subClientes.contacto} name='contacto' onChange={handleEditSubClientes} />
                            <i class="icon fa-solid fa-phone" />
                        </div>
                    </div>
                    <div className="col-md-2 mt-3">
                        <button className='btn-add' onClick={handleAddSubcliente}><i class="fa-solid fa-circle-plus"></i> Agregar subcliente</button>
                    </div>
                    <div className='col-md-12 mb-2'> </div>
                    {subClientesList && subClientesList.map((c, index) => (
                        <>
                            <div className="col-md-2 ">
                                <label className='label-title'>Rut</label>
                                <br />
                                <label className='label-white'>{c.rut}</label>
                            </div>

                            <div className="col-md-3 ">
                                <label className='label-title'>Razón Social</label>
                                <br />
                                <label className='label-white'>{c.razon_social}</label>
                            </div>
                            <div className="col-md-2 ">
                                <label className='label-title'>Email</label>
                                <br />
                                <label className='label-white'>{c.email}</label>
                            </div>

                            <div className="col-md-2 ">
                                <label className='label-title'>Dirección</label>
                                <br />
                                <label className='label-white'>{c.direccion}</label>
                            </div>
                            <div className="col-md-2 ">
                                <label className='label-title'>Contacto</label>
                                <br />
                                <label className='label-white'>{c.contacto}</label>
                            </div>

                            <div className="col-md-1 ">
                                <button className='btn-delete' onClick={() => deleteSubCliente(index)}><i class="fa-solid fa-trash"></i></button>
                            </div>
                        </>
                    ))}

                </div>
                <div className='col-md-12 mt-3'>
                    <button onClick={handleSubmit} className='btn-blue'> <i class="fa-regular fa-floppy-disk"></i> Guardar</button>
                </div>
            </div>
        </>
    )
}

export default ClienteCreate