import React,{ useState } from 'react'
import AdminNav from '../../components/navs/AdminNav'
import {
    Card,
    Grid,
    Title,
    Tab,
    TabList,
    TabGroup,
    TabPanel,
    TabPanels,
    BarChart,
    BadgeDelta,
    Flex,
    Metric,
    ProgressBar,
    Text,
    Icon,  
    AreaChart,
    LineChart,
    Color
} from "@tremor/react";


import { InformationCircleIcon } from "@heroicons/react/solid";



const Dashboard = () => {

    const chartdata = [
        {
          year: 1970,
          "Line 1": 2.04,
          "Line 2": 1.53,
        },
      ];
      
      // Generate data for years from 1971 to 2023
      for (let year = 1971; year <= 2023; year++) {
        const newDataPoint = {
          year: year,
          "Line 1": getRandomValue(-1, 1),
          "Line 2": getRandomValue(1, 4),
        };
        chartdata.push(newDataPoint);
      }
      
      function getRandomValue(min, max) {
        return (Math.random() * (max - min) + min).toFixed(2);
      }
      
      const dataFormatter = (number: number) => `${Intl.NumberFormat("us").format(number).toString()}%`;




    return (
        <div>
            <AdminNav />

            <div className="subNav">
                <span> Admin / Dashboard</span>
            </div>

            <div className='contenedorTm p-4'>

                <Title>Dashboard</Title>
                <TabGroup className="mt-6">
                    <TabList>
                        <Tab>Movimientos</Tab>
                        <Tab>Aprobaciones</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <Grid numItemsMd={2} numItemsLg={3} className="gap-6 mt-6">
                                             
                            </Grid>
                            <div className="mt-6">
                            <Card>
    <Title>Data example</Title>
    <LineChart
      className="mt-6"
      data={chartdata}
      index="year"
      categories={["Line 1", "Line 2"]}
      colors={["emerald", "gray"]}
      valueFormatter={dataFormatter}
      yAxisWidth={40}
    />
  </Card>
              
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="mt-6">
                                <Card>
                                    <div className="h-96" />
                                </Card>
                            </div>
                        </TabPanel>
                    </TabPanels>
                </TabGroup>
            </div>
        </div>
    )
}


export default Dashboard