import './App.css';
import React, { Fragment, useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';




//Funciones
import { getCurrentUser } from './functions/api/auth'


//Routes
import AdminRoute from './components/routes/AdminRoute'
import OperadorRoute from './components/routes/OperadorRoute';



import Login from './pages/Login'

// Admin 
import Home from './pages/admin/Home';
import UsersList from './pages/admin/users/UsersList';
import AlmacenesList from './pages/admin/almacenes/AlmacenesList';
import ChoferesList from './pages/admin/choferes/ChoferesList';
import ClientesList from './pages/admin/clientes/ClientesList';
import IngresarMovimiento from './pages/operador/IngresarMovimiento';
import ResumenMovimiento from './pages/operador/ResumenMovimiento';
import TipoContenedoresList from './pages/admin/tipoContenedores/TipoContenedoresList';
import FlotaList from './pages/admin/flota/FlotaList';
import EstadoList from './pages/admin/estado/EstadoList';
import Dashboard from './pages/admin/Dashboard';
import ResumenMovimientoAdmin from './pages/admin/ResumenMovimientoAdmin';
import Aprobaciones from './pages/admin/Aprobaciones';
import Stock from './pages/operador/Stock';
import Notfound from './components/routes/Notfound';
import ReporteView from './pages/admin/ReporteView';


function App() {

  const dispatch = useDispatch()

  useEffect(() => {

    const checkUser = async () => {

      const token = localStorage.getItem('token')

      if (token !== null) {
        getCurrentUser({ token: localStorage.getItem('token') })
          .then((r) => {
            dispatch({
              type: 'LOGGED_IN_USER',
              payload: {
                id: r.data.id,
                email: r.data.email,
                nombre_completo: r.data.nombre_completo,
                role: r.data.role,
                token: localStorage.getItem('token')
              },
            })

          }
          )
          .catch(
            err => {
              console.log(err)
            }
          )
      }
    }

    checkUser()

  }, [dispatch]);


  return (

    <Fragment>
      <ToastContainer />
      <Routes>
          <Route exact path='/' element={<Login />} />

          {/* Admin Routes */}
          <Route exact path='/admin/home' element={<AdminRoute Component={Home} />} />

          <Route exact path='/admin/users' element={<AdminRoute Component={UsersList} />} />
          <Route exact path='/admin/almacenes' element={<AdminRoute Component={AlmacenesList} />} />
          <Route exact path='/admin/choferes' element={<AdminRoute Component={ChoferesList} />} />
          <Route exact path='/admin/clientes' element={<AdminRoute Component={ClientesList} />} />
          <Route exact path='/admin/tipo-contenedores' element={<AdminRoute Component={TipoContenedoresList} />} />
          <Route exact path='/admin/flota' element={<AdminRoute Component={FlotaList} />} />
          <Route exact path='/admin/estado' element={<AdminRoute Component={EstadoList} />} />
          <Route exact path='/admin/dashboard' element={<AdminRoute Component={Dashboard} />} />
          <Route exact path='/admin/movimientos' element={<AdminRoute Component={ResumenMovimientoAdmin} />} />
          <Route exact path='/admin/aprobaciones' element={<AdminRoute Component={Aprobaciones} />} />
          <Route exact path='/admin/reporte' element={<AdminRoute Component={ReporteView} />} />

          {/* Operador Routes */}
          <Route exact path='/operador/ingresar-movimiento' element={<OperadorRoute Component={IngresarMovimiento} />} />
          <Route exact path='/operador/registro-movimientos' element={<OperadorRoute Component={ResumenMovimiento} />} />
          <Route exact path='/operador/stock' element={<OperadorRoute Component={Stock} />} />
       

          <Route path='*' element={<Notfound/>}/>
      </Routes>
    </Fragment>
  )
}

export default App;

