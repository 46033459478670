import React from 'react';
import ReactDOM from "react-dom/client";
import {BrowserRouter} from 'react-router-dom'

import './App.css';
import App from './App';
import {NextUIProvider} from "@nextui-org/react";

import { configureStore } from '@reduxjs/toolkit'

import {Provider} from 'react-redux'
import {composeWithDevTools} from 'redux-devtools-extension'

import rootReducer from './reducers'

const store = configureStore({reducer:rootReducer},{devTools:composeWithDevTools()})



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <NextUIProvider>
    <Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
        </Provider>

    </NextUIProvider>
);


if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/serviceWorker.js')
}

