import React from 'react'
import Loading from '../../img/loading2.gif'

const loading = () => {
  return (
    <>
    <div className="loading-fade">
        
    <div className='loading'>
        <img src={Loading} alt='loading'/>
        <p>Cargando....</p>
    </div>
    </div>
    </>
  )
}

export default loading