import React from 'react'
import { formatRut } from '../../../functions/utilities/format'

const AlmacenDetail = ({ handleModal, handleUpdate, handleChangeUpdate, valuesUpdate }) => {

    const { codigo,nombre,direccion } = valuesUpdate && valuesUpdate



    return (
        <>
            <div className='modalBackground' onClick={handleModal}>
            </div>
            <div className='modalCard'>
                <div className="row">
                    <div className="col-md-12">
                        <p className='modalTitle'>ACTUALIZAR ALMACÉN</p>
                    </div>
                    <div className="col-md-2 ">

                <label className='label-title'>Código</label>
                <br/>
                <input className='loginInput' value={codigo} name='codigo' onChange={handleChangeUpdate} />
                
            </div>
            <div className="col-md-5 ">
                <label className='label-title'>Nombre</label>
                <br/>
                <input className='loginInput' value={nombre} name='nombre' onChange={handleChangeUpdate} />
                
            </div>
            <div className="col-md-5 ">
                <label className='label-title'>Dirección</label>
                <br/>
                <input className='loginInput' value={direccion} name='direccion' onChange={handleChangeUpdate} />
                
            </div>
            

                    <div className='col-md-12 mt-5'>
                        <button onClick={handleUpdate} className='btn-blue'> <i class="fa-regular fa-floppy-disk"></i> Guardar</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AlmacenDetail