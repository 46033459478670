import axios from 'axios'


export const userLogin = async(userData) =>{
    return await axios.post(`${process.env.REACT_APP_API}/login`,userData)
}


export const getCurrentUser = async(userToken) =>{
    return await axios.post(`${process.env.REACT_APP_API}/auth`,userToken)
}



export const getUsers = async(token,page,per_page) =>{
    return await axios.get(`${process.env.REACT_APP_API}/users?page=${page}&per_page=${per_page}`,{
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
}


export const getUserById = async(token,id) =>{
    return await axios.get(`${process.env.REACT_APP_API}/users_by_id/${id}`,{
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
}


export const updateUser = async(token,id,body) =>{
    return await axios.put(`${process.env.REACT_APP_API}/users/${id}`,body,{
        
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
}


export const createUser = async(token,body) =>{
    return await axios.post(`${process.env.REACT_APP_API}/signup`,body,{
        
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
}



export const solicitarModificacion = async(token,body) =>{
    return await axios.put(`${process.env.REACT_APP_API}/update_movimiento`,body,{
        
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
}