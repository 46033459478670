import React from "react";

const FlotaDetail = ({
  handleModal,
  handleChangeUpdate,
  handleUpdate,
  valuesUpdate,
  conductores,
  handleDelete,
}) => {
  const { placa_patente, chofer_id } = valuesUpdate;

  return (
    <>
      <div className="modalBackground" onClick={handleModal}></div>
      <div className="modalCard">
        <div className="row">
          <div className="col-md-10">
            <p className="modalTitle">ACTUALIZAR CAMIÓN</p>
          </div>
          <div className="col-md-2">
            <button onClick={handleDelete} className="btn btn-danger">
              Eliminar Registro
            </button>
          </div>
          <div className="col-md-6 mt-2 ">
            <label className="label-title">Patente</label>
            <br />
            <input
              className="loginInput"
              value={placa_patente}
              name="placa_patente"
              onChange={handleChangeUpdate}
            />
          </div>
          <div className="col-md-6 mt-2 ">
            <label className="label-title">Conductor Asignado</label>
            <br />
            <select
              className="loginInput"
              value={chofer_id}
              name="chofer_id"
              onChange={handleChangeUpdate}
            >
              <option>Seleccionar Chofer</option>
              {conductores &&
                conductores.map((c) => (
                  <option key={c.id} value={c.id}>
                    {c.nombre_completo}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-md-12 mt-3">
            <button onClick={handleUpdate} className="btn-blue">
              {" "}
              <i class="fa-regular fa-floppy-disk"></i> Actualizar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FlotaDetail;
