import React from 'react'

const FlotaCreate = ({ handleSubmit, handleChange, values, conductores }) => {



    const { placa_patente,chofer_id } = values


    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <p className='modalTitle'>CREAR CAMIÓN</p>
                </div>

                <div className="col-md-6 mt-2 ">
                    <label className='label-title'>Patente</label>
                    <br />
                    <input className='loginInput' value={placa_patente} name='placa_patente' onChange={handleChange} />

                </div>
                <div className="col-md-6 mt-2 ">
                    <label className='label-title'>Conductor Asignado</label>
                    <br />
                    <select className='loginInput' value={chofer_id} name='chofer_id' onChange={handleChange} >
                        <option>Seleccionar Chofer</option>
                        {
                            conductores && conductores.map( c => (
                                <option key={c.id} value={c.id}>{c.nombre_completo}</option>
                            ))
                        }
                    </select>

                </div>
                <div className='col-md-12 mt-3'>
                    <button onClick={handleSubmit} className='btn-blue'> <i class="fa-regular fa-floppy-disk"></i> Guardar</button>
                </div>
            </div>
        </>
    )
}

export default FlotaCreate