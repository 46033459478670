import React from 'react'
import {useSelector} from 'react-redux'
import LoadingToRedirect from './LoadingToRedirect'


const AdminRoute = ({Component}) =>{

    const {user} = useSelector((state) => ({...state}))

    return user && user.token && user.role === 'admin' ? 
    (
    <Component/>
    ) : (
    <LoadingToRedirect/>
    )
}


export default AdminRoute