import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

// Componentes
import AdminNav from '../../../components/navs/AdminNav'
import ClientesTable from '../../../components/tables/ClientesTable'
import ClienteCreate from './ClienteCreate'
import ClienteDetail from './ClienteDetail'
import Loading from '../../../components/others/loading'
import SubClientesList from './SubClientesList'


// Funciones
import { getClientes, createCliente, updateCliente } from '../../../functions/api/clientes'
import { getSubClientes, createSubCliente, updateSubCliente } from '../../../functions/api/subclientes'
import { formatRut } from '../../../functions/utilities/format'
import SubclienteCreate from './SubclienteCreate'

const initialState = {
  "razon_social": "",
  "rut": "",
  "direccion": "",
  "email": "",
  "contacto": "+56 9 ",
  "activo": true
}


const subclienteState = {
  "razon_social": "",
  "rut": "",
  "direccion": "",
  "email": "",
  "contacto": "+56 9 ",
  "activo": true,
  "cliente_id": ''
}

const filterInitialState = {
  "razon_social": "",
  "rut": "",
  "direccion": "",
  "email": "",
  "contacto": "",
}

const AlmacenesList = () => {

  const [valuesUpdate, setValuesUpdate] = useState([])
  const [values, setValues] = useState(initialState)
  const [clientes, setClientes] = useState([])
  const [rutUpdate, setRutUpdate] = useState('')
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(100)
  const [modal, setModal] = useState(false)
  const [modalSubclienteAdd, setModalSubclienteAdd] = useState(false)
  const [subClientesData, setSubclientesData] = useState([])
  const [subClientes, setSubClientes] = useState(subclienteState)
  const [subClientesList, setSubClientesList] = useState([])
  const [modalSubCliente, setModalSubCliente] = useState(false)
  const [valuesSubClientesUpdate, setValuesSubClientesUpdate] = useState([])
  const [filters, setFilters] = useState(filterInitialState)
  const id = useRef()

  let { user } = useSelector((state) => ({ ...state }))

  useEffect(() => {

    loadClientes()
    loadSubClientes()

  }, []);

  const loadClientes = () => getClientes(user.token, page, perPage).then(res => {
    setClientes(res.data)
  }).catch(err => console.log(err))


  const loadSubClientes = () => getSubClientes(user.token, page, perPage).then(res => {
    setSubclientesData(res.data)
  }).catch(err => console.log(err))


  const handleModal = (e) => {
    e.preventDefault(e)
    id.current = 1

    if (modal === false) {
      setLoading(true)
      id.current = e.target.value
      let filteredData = clientes.filter(d => d.id === Number(id.current));
      setValuesUpdate(filteredData[0])

      const rutNuevo = filteredData[0].rut.replace('*', '').replace('-', '')
      setRutUpdate(formatRut(rutNuevo))
      setLoading(false)
    } else {
      setValuesUpdate([])
    }

    setModal(!modal)
  }

  const handleModalSubcliente = (e) => {

    e.preventDefault(e)
    id.current = 1

    console.log(e.target.value)
    if (modalSubCliente === false) {
      setLoading(true)
      id.current = e.target.value
      console.log(subClientesData)
      let filteredData = subClientesData.filter(d => d.cliente_id === Number(id.current));
      setValuesSubClientesUpdate(filteredData)
      console.log(filteredData)
      setLoading(false)
    } else {
      setValuesUpdate([])
    }

    setModalSubCliente(!modalSubCliente)
  }

  const handleModalSubclienteAdd = (e) => {
    e.preventDefault()

    if (modalSubclienteAdd === false) {
      id.current = e.target.value
    }

    setModalSubclienteAdd(!modalSubclienteAdd)

  }

  const handleSubclienteChange = (e) => {
    setSubClientes({ ...subClientes, [e.target.name]: e.target.value })
  }

  const handleSubclienteCreate = () => {
    subClientes.cliente_id = id.current

    setLoading(true)
    if (subClientes.razon_social === '') {
      toast.error('Ingrese rut de subcliente')
    } else {
      createSubCliente(user.token, subClientes)
        .then(res => {
          toast.success('Subcliente creado exitosamente!')
          setModalSubclienteAdd(!modalSubclienteAdd)
          loadSubClientes()
          setSubClientes(subclienteState)
          setLoading(false)
        })
        .catch(err => {
          toast.error(err.response.data.detail)
          setModalSubclienteAdd(!modalSubclienteAdd)
          setLoading(false)
        })
    }

  }



  const handleChange = (e) => {
    e.preventDefault()
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    if (values.razon_social === '') {
      toast.error('Ingresar rut de Cliente')
      setLoading(false)
    } {
      createCliente(user.token, values)
        .then(res => {
          toast.success('Cliente creado exitosamente')
          setValues(initialState)
          loadClientes()
          for (let i = 0; i < subClientesList.length; i++) {
            subClientesList[i].cliente_id = res.data.id
            createSubCliente(user.token, subClientesList[i]).then(res => {
              console.log(res.data)
            }).catch(err => console.log(err))
          }
          loadSubClientes()
          setSubClientesList([])

          setLoading(false)
        })
        .catch(err => {
          toast.error(err.response.data.detail)
          setLoading(false)
        })
    }
  }

  const handleChangeUpdate = (e) => {
    setValuesUpdate({ ...valuesUpdate, [e.target.name]: e.target.value })
  }

  const handleEditSubClientesUpdate = (index) => (e) => {
    const newArray = valuesSubClientesUpdate.map((item, i) => {
      if (index === i) {
        return { ...item, [e.target.name]: e.target.value };
      } else {
        return item;
      }
    });
    setValuesSubClientesUpdate(newArray);

  };



  const handleUpdate = (e) => {
    e.preventDefault()


    let data = {}
    data.razon_social = valuesUpdate.razon_social
    data.email = valuesUpdate.email
    data.rut = rutUpdate
    data.direccion = valuesUpdate.direccion
    data.contacto = valuesUpdate.contacto
    data.activo = true

    setLoading(true)
    console.log(data)
    updateCliente(user.token, Number(id.current), data)
      .then(res => {
        toast.success('Cliente actualizado exitosamente')
        loadClientes()
        loadSubClientes()
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
        setLoading(false)

      })

    setValuesUpdate([])
    setModal(!modal)

  }

  const handleClienteDelete = (e) => {
    e.preventDefault()


    let data = {}
    data.razon_social = valuesUpdate.razon_social
    data.email = valuesUpdate.email
    data.rut = rutUpdate
    data.direccion = valuesUpdate.direccion
    data.contacto = valuesUpdate.contacto
    data.activo = false

    setLoading(true)
    console.log(data)
    updateCliente(user.token, Number(id.current), data)
      .then(res => {
        toast.success('Cliente actualizado exitosamente')
        loadClientes()
        loadSubClientes()
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
        setLoading(false)

      })

    setValuesUpdate([])
    setModal(!modal)

  }

  const handleRutUpdate = (e) => {
    setRutUpdate(formatRut(e.target.value))
  }


  const handleAddSubcliente = (e) => {
    if (subClientes.rut === '') {
      toast.error('Favor agregar rut de SubCliente')
    } else {

      setSubClientesList(prevData => [...prevData, subClientes]);
      setSubClientes(subclienteState)
    }
  }

  const deleteSubCliente = (index) => {
    const updatedArray = [...subClientesList]; // Create a copy of the array
    updatedArray.splice(index, 1); // Remove the item at the specified position
    setSubClientesList(updatedArray);
  };

  const handleEditSubClientes = (e) => {
    setSubClientes({ ...subClientes, [e.target.name]: e.target.value })
  }

  const handleUpdateSubCliente = () => {


    setLoading(true)
    for (let i = 0; i < valuesSubClientesUpdate.length; i++) {


      if (subClientes.razon_social === '') {
        toast.error('Favor agregar rut de SubCliente')
        setLoading(false)
      } else {
        updateSubCliente(user.token, valuesSubClientesUpdate[i].id, valuesSubClientesUpdate[i]).then(res => {

          toast.success('SubClientes Actualizados Exitosamente')
          loadClientes()
          loadSubClientes()
          setModalSubCliente(!modalSubCliente)
          setLoading(false)

        }).catch(err => {
          toast.error(err.response.data.detail)
          setModalSubCliente(!modalSubCliente)
          setLoading(false)
        })
      }
    }


  }

  // const handleEditSubClientesUpdate = (index) => (e) => {
  //   const newArray = valuesSubClientesUpdate.map((item, i) => {
  //     if (index === i) {
  //       return { ...item, [e.target.name]: e.target.value };
  //     } else {
  //       return item;
  //     }
  //   });
  //   setValuesSubClientesUpdate(newArray);

  // };

  const handleDeleteSubCliente = index => (e) => {

    setLoading(true)


    valuesSubClientesUpdate[index].activo = false


    updateSubCliente(user.token, valuesSubClientesUpdate[index].id, valuesSubClientesUpdate[index]).then(res => {

      toast.success('SubClientes Actualizados Exitosamente')
      loadClientes()
      loadSubClientes()
      setModalSubCliente(!modalSubCliente)
      setLoading(false)

    }).catch(err => {
      toast.error(err.response.data.detail)
      setModalSubCliente(!modalSubCliente)
      setLoading(false)
    })


  }


  const data = clientes &&
    clientes
      .filter(d => d.activo === true)
      .filter(d => d.rut.toUpperCase().includes(filters.rut.toUpperCase()))
      .filter(d => d.razon_social.toUpperCase().includes(filters.razon_social.toUpperCase()))

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value })
  }

  return (
    <div>
      {modal && (
        <ClienteDetail
          handleModal={handleModal}
          handleUpdate={handleUpdate}
          handleChangeUpdate={handleChangeUpdate}
          valuesUpdate={valuesUpdate}
          rutUpdate={rutUpdate}
          handleClienteDelete={handleClienteDelete}
          setRutUpdate={setRutUpdate}
          handleRutUpdate={handleRutUpdate}
        />
      )}

      {modalSubCliente && (
        <SubClientesList
          handleDeleteSubCliente={handleDeleteSubCliente}
          handleModalSubcliente={handleModalSubcliente}
          valuesSubClientesUpdate={valuesSubClientesUpdate}
          handleUpdateSubCliente={handleUpdateSubCliente}
          handleEditSubClientesUpdate={handleEditSubClientesUpdate}

        />
      )}


      {modalSubclienteAdd && (
        <SubclienteCreate
          handleModalSubclienteAdd={handleModalSubclienteAdd}
          handleSubclienteChange={handleSubclienteChange}
          handleSubclienteCreate={handleSubclienteCreate}
          subClientes={subClientes}

        />
      )}

      <AdminNav />
      {loading ? (
        <Loading />
      ) : (

        <>

          <div className="subNav">
            <span>Admin / Clientes</span>
          </div>
          <div className='contenedorTm'>

            <div className="form-container">
              <ClienteCreate
                handleSubmit={handleSubmit}
                handleEditSubClientes={handleEditSubClientes}
                handleChange={handleChange}
                values={values}
                handleAddSubcliente={handleAddSubcliente}
                subClientesList={subClientesList}
                subClientes={subClientes}
                deleteSubCliente={deleteSubCliente}
              />
            </div>
            <div className="table-title">
              <h6 >CLIENTES</h6>
            </div>
            <div className='grid grid-cols-6 gap-6 p-1'>

              <input
                value={filters.rut}
                name='rut'
                onChange={handleFilterChange}
                type='text'
                placeholder='Filtrar por rut cliente'
                className='p-1 rounded-full'
              />

              <input
                value={filters.razon_social}
                name='razon_social'
                onChange={handleFilterChange}
                type='text'
                placeholder='Filtrar por razón social'
                className='p-1 rounded-full'
              />
            </div>
            <div className="table-container">
              <ClientesTable
                values={data}
                handleModal={handleModal}
                handleModalSubcliente={handleModalSubcliente}
                handleModalSubclienteAdd={handleModalSubclienteAdd}
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default AlmacenesList