import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

// Componentes
import AdminNav from '../../../components/navs/AdminNav'
import AlmacenesTable from '../../../components/tables/AlmacenesTable'
import AlmacenCreate from './AlmacenCreate'
import Loading from '../../../components/others/loading'
import AlmacenDetail from './AlmacenDetail'

// Funciones
import { getAlmacenes, createAlmacen, updateAlmacen } from '../../../functions/api/almacenes'


const initialState = {
  "codigo": "",
  "nombre": "",
  "direccion": "",
  "activo": true
}

const AlmacenesList = () => {

  const [values, setValues] = useState(initialState)
  const [valuesUpdate, setValuesUpdate] = useState([])
  const [almacenes, setAlmacenes] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [modal, setModal] = useState(false)
  const [perPage, setPerPage] = useState(100)
  const [almacenId, setAlmacenId] = useState('')
  const id = useRef()

  let { user } = useSelector((state) => ({ ...state }))

  useEffect(() => {

    loadAlmacenes()

  }, []);

  const loadAlmacenes = () => getAlmacenes(user.token, page, perPage).then(res => setAlmacenes(res.data)).catch(err => console.log(err))



  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {

    setLoading(true)
    if (values.nombre === '' || values.codigo === '') {
      toast.error('Ingrese nombre y codigo')
      setLoading(false)
    } else {
      createAlmacen(user.token, values)
        .then(res => {
          toast.success('Almacen creado exitosamente')
          loadAlmacenes()
          setValues(initialState)
        })
        .catch(err => {
          toast.error(err.response.data.detail)
          setLoading(false)
        })
    }
  }



const handleChangeUpdate = (e) => {
  setValuesUpdate({ ...valuesUpdate, [e.target.name]: e.target.value })
}

const handleUpdate = (e) => {

  let data = {}
  data.codigo = valuesUpdate.codigo
  data.nombre = valuesUpdate.nombre
  data.direccion = valuesUpdate.direccion
  data.activo = valuesUpdate.activo


  setLoading(true)
  if (data.nombre === '' || data.codigo === '') {
    toast.error('Ingrese nombre y codigo')
    setLoading(false)
  } else {
  updateAlmacen(user.token, Number(id.current), data)
    .then(res => {
      toast.success('Almacen actualizado exitosamente')
      loadAlmacenes()
      setLoading(false)
    })
    .catch(err => {
      toast.error(err.response.data.detail)
      setLoading(false)

    })}

  setValuesUpdate([])
  setModal(!modal)
}



const handleModal = (e) => {
  e.preventDefault(e)
  id.current = 1

  if (modal === false) {
    setLoading(true)
    id.current = e.target.value
    let filteredData = almacenes.filter(d => d.id === Number(id.current));
    setValuesUpdate(filteredData[0])
    setLoading(false)
  } else {
    setValuesUpdate([])
  }

  setModal(!modal)
}


return (
  <div>
    {loading ? (
      <Loading />
    ) : (
      modal && valuesUpdate && valuesUpdate.length !== 0 && (
        <AlmacenDetail
          handleModal={handleModal}
          valuesUpdate={valuesUpdate}
          handleChangeUpdate={handleChangeUpdate}
          handleUpdate={handleUpdate}
        />
      ))}


    {/* {JSON.stringify(valuesUpdate)} */}
    <AdminNav />
    <div className="subNav">
      <span>Admin / Almacenes</span>
    </div>
    <div className='contenedorTm'>
      <div className="form-container">
        <AlmacenCreate
          values={values}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        />
      </div>
      <div className=" table-title">
        <h6 className='p-0 m-0'>ALMACENES</h6>
      </div>
      <div className="table-container">
        <AlmacenesTable
          values={almacenes}
          handleModal={handleModal}
        />
      </div>
    </div>
  </div>
)
}

export default AlmacenesList