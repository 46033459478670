import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

// Componentes
import AdminNav from '../../../components/navs/AdminNav'
import EstadosTable from '../../../components/tables/EstadosTable'
import EstadoCreate from './EstadoCreate'
import EstadoDetail from './EstadoDetail'

// Funciones
import { getEstados, createEstado, updateEstado } from '../../../functions/api/estados'

const initialState = {
  "descripcion": "",
  "activo": true
}

const EstadoList = () => {

  const [valuesUpdate, setValuesUpdate] = useState([])
  const [values, setValues] = useState(initialState)
  const [estados, setEstados] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(100)
  const [modal, setModal] = useState(false)
  const id = useRef()

  let { user } = useSelector((state) => ({ ...state }))

  useEffect(() => {

    loadEstados()

  }, []);

  const loadEstados = () => getEstados(user.token, page, perPage).then(res => {

    setEstados(res.data)
  }).catch(err => console.log(err))

  const handleModal = (e) => {
    e.preventDefault(e)
    id.current = 1

    if (modal === false) {
      setLoading(true)
      id.current = e.target.value
      let filteredData = estados.filter(d => d.id === Number(id.current));
      setValuesUpdate(filteredData[0])
      
      setLoading(false)
    } else {
      setValuesUpdate([])
    }

    setModal(!modal)
  }


  const handleChange = (e) => {
    e.preventDefault()
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    createEstado(user.token, values)
      .then(res => {
        toast.success('Estado creado exitosamente')
        setModal(!modal)
        setValues(initialState)
        loadEstados()
      })
      .catch(err => console.log(err))
  }

  const handleChangeUpdate = (e) => {
    setValuesUpdate({ ...valuesUpdate, [e.target.name]: e.target.value })
  }


  const handleUpdate = (e) => {
    e.preventDefault()


    let data = {}
    data.descripcion = valuesUpdate.descripcion
    data.activo = true

    setLoading(true)
    console.log(data)
    updateEstado(user.token, Number(id.current), data)
      .then(res => {
        toast.success('Estado actualizado exitosamente')
        loadEstados()
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
        setLoading(false)

      })

    setValuesUpdate([])
    setModal(!modal)

  }

 

  return (
    <div>

      <AdminNav />
      {modal && (
        <EstadoDetail
          handleModal={handleModal}
          handleUpdate={handleUpdate}
          handleChangeUpdate={handleChangeUpdate}
          valuesUpdate={valuesUpdate}
        />
      )}
      <div className="subNav">
        <span>Admin / Estados</span>
      </div>
      <div className='contenedorTm'>
        {/* <div className="form-container">
          <EstadoCreate
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            values={values}
          />
        </div> */}
        <div className="table-title">
          <h6 >ESTADOS</h6>
        </div>
        <div className="table-container">
          <EstadosTable
            values={estados}
            handleModal2={handleModal}
          />
        </div>
      </div>
    </div>
  )
}

export default EstadoList