import React from 'react'

const UserCreate = ({handleChange,handleSubmit,values}) => {



    const { nombre_completo, email, contrasena, role } = values


  return (
    <>
        <div className="row">
            <div className="col-md-12">
                <p className='modalTitle'>CREAR USUARIO</p>
            </div>
            <div className="col-md-3 ">
                <label className='label-title'>Nombre Completo</label>
                <br/>
                <input className='loginInput' value={nombre_completo} name='nombre_completo' onChange={handleChange} />
            </div>
            <div className="col-md-3 ">
                <label className='label-title'>Email</label>
                <br/>
                <input required className='loginInput' value={email} name='email'  onChange={handleChange}/>
            </div>
            <div className="col-md-3 ">
                <label className='label-title'>Contraseña</label>
                <br/>
                <input required className='loginInput'  type='password' value={contrasena} name='contrasena' onChange={handleChange} />
            </div>
            <div className="col-md-3 ">
                <label className='label-title'>Rol</label>
                <br/>
                <select className='loginInput' name="role" value={role} onChange={handleChange}>
                    <option value=''>Seleccionar rol</option>
                    <option value='admin'>Admin</option>
                    <option value='operator'>Operador</option>
                    <option value='user'>User</option>
                </select>
            </div>
            <div className='col-md-12 mt-3'>
                <button onClick={handleSubmit} className='btn-blue'> <i class="fa-regular fa-floppy-disk"></i> Guardar</button>
            </div>
        </div>
</>
  )
}

export default UserCreate