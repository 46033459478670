import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

//componentes
import OperatorNav from "../../components/navs/OperatorNav";
import Loading from "../../components/others/loading";
import StockTable from "../../components/tables/StockTable";

import {
  getMovimientos,
  updateMovimientoSalida,
} from "../../functions/api/movimientos";
import { getChoferes } from "../../functions/api/choferes";
import { getFlota } from "../../functions/api/flota";

const initialState = {
  fecha_salida: "",
  hora_salida: "",
  chofer_id_salida: 0,
  estado: "DESPACHADO",
  camion_id_salida: 0,
  fecha_egreso: "",
};

const Stock = () => {
  const [loading, setLoading] = useState(false);
  const [updateData, setUpdateData] = useState(initialState);
  const [data, setData] = useState([]);
  const [choferes, setChoferes] = useState([]);
  const [flota, setFlota] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);

  let { user } = useSelector((state) => ({ ...state }));

  useEffect(() => {
    loadMovimientos();
    loadChoferes();
    loadFlota();
  }, []);

  const loadMovimientos = () => {
    setLoading(true);

    getMovimientos(user.token, page, perPage)
      .then((res) => {
        setData(res.data.data.filter((d) => d.activo === true));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const loadFlota = () => {
    setLoading(true);
    getFlota(user.token, page, perPage)
      .then((res) => {
        setFlota(res.data.filter((d) => d.activo === true));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const loadChoferes = () => {
    setLoading(true);
    getChoferes(user.token, page, perPage)
      .then((res) => {
        setChoferes(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    if (e.target.name === "camion_id_salida") {
      console.log(e.target.value);
      console.log(flota);
      let camion = flota.filter((d) => d.id === Number(e.target.value));
      console.log(camion[0].chofer_id);
      updateData.chofer_id_salida = camion[0].chofer_id;
    }
    setUpdateData({ ...updateData, [e.target.name]: e.target.value });
  };

  const detailMov = useRef([]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let currentDate;

    if (!updateData.fecha_egreso) {
      currentDate = new Date();
    } else {
      currentDate = new Date(updateData.fecha_egreso);
    }

    console.log(currentDate);

    var year = currentDate.getFullYear();
    var month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 to month since it's zero-based
    var day = currentDate.getDate().toString().padStart(2, "0");

    // Get the current hour
    var currentHour = currentDate.getHours().toString().padStart(2, "0");
    var currentMinutes = currentDate.getMinutes().toString().padStart(2, "0");

    var formattedDate = year + "-" + month + "-" + day;

    updateData.fecha_salida = formattedDate;
    updateData.hora_salida = currentHour + ":" + currentMinutes;

    setLoading(true);
    updateMovimientoSalida(user.token, updateData, Number(detailMov.current.id))
      .then((res) => {
        loadMovimientos();
        toast.success("Movimiento actualizado exitosamente");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);

        setLoading(false);
      });
  };

  return loading ? (
    <Loading />
  ) : (
    <div>
      <OperatorNav />
      <div className="m-5">
        <StockTable
          data={data.filter((d) => d.estado === "STOCK")}
          loading={loading}
          updateData={updateData}
          flota={flota}
          choferes={choferes}
          detailMov={detailMov}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          loadChoferes={loadChoferes}
          loadFlota={loadFlota}
        />
      </div>
    </div>
  );
};

export default Stock;
