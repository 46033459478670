import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Input, Select, SelectItem } from "@nextui-org/react";
import { createFlota } from '../../functions/api/flota';
import { getChoferes } from '../../functions/api/choferes';
import { toast } from 'react-toastify';

const initialState = {
    "placa_patente": "",
    "activo": true,
    "chofer_id": null
}


const ModalCamion = ({ isOpen, onOpenChange, setModalCamion, loadFlota }) => {


    const [values, setValues] = useState(initialState)
    const [loading, setLoading] = useState(false)
    const [conductores, setConductores] = useState([])
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(100)


    let { user } = useSelector((state) => ({ ...state }))


    useEffect(() => {

        loadConductores()

    }, []);

    const loadConductores = () => getChoferes(user.token, page, perPage).then(res => setConductores(res.data)).catch(err => console.log(err))



    const { placa_patente, chofer_id } = values

    const handleSubmit = (e) => {
        e.preventDefault()

        setLoading(true)
        if (values.placa_patente === '') {
            toast.error('Seleccione Chofer e ingrese patente de camión.')
            setLoading(false)
        } else {
            createFlota(user.token, values)
                .then(res => {
                    toast.success('Camión creado exitosamente')
                    setValues(initialState)
                    onOpenChange()
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    toast.success(err)
                    onOpenChange()
                    toast.error(err.response.data.detail)
                })
        }

    }

    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
    }

    return (
        <Modal isOpen={isOpen} >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">CREAR CAMIÓN</ModalHeader>
                        <ModalBody>
                            <Input
                                type="text"
                                label="Placa Patente"
                                labelPlacement='outside'
                                placeholder="Ingrese placa patente"
                                value={placa_patente}
                                variant='bordered'
                                className='uppercase'
                                name='placa_patente'
                                onChange={handleChange}
                            />

                            <Select
                                items={conductores}
                                label="Chofer"
                                labelPlacement='outside'
                                placeholder="Seleccionar Camión"
                                variant='bordered'
                                value={chofer_id}
                                name='chofer_id'
                                onChange={handleChange}
                            >
                                {(conductores) => <SelectItem key={conductores.id}>{conductores.nombre_completo}</SelectItem>}
                            </Select>



                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" variant="light" onPress={onOpenChange}>
                                Cerrar
                            </Button>
                            <Button color="primary" onClick={handleSubmit}>
                                Guardar
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    )
}

export default ModalCamion