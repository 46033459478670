import React, { useCallback, useMemo, useState, useRef } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  Spinner,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  TableCell,
  Pagination,
  Chip,
  Input,
  Switch,
} from "@nextui-org/react";

import Loading from "../others/loading";

import { DeleteIcon } from "../../img/DeleteIcon";
import { EditIcon } from "../../img/EditIcon";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { EyeIcon } from "../../img/EyeIcon";

const filtersInitialState = {
  numero_contenedor: "",
  numero_guia: "",
  fecha_recepcion_desde: "",
  fecha_recepcion_hasta: "",
};

const MovimientosTableAdmin = ({ values, handleModal, loading }) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [openEye, setOpenEye] = useState(false);
  const [filterValue, setFilterValue] = useState(filtersInitialState);

  const columns = [
    {
      label: "ID",
      key: "id",
    },
    {
      label: "Recepción",
      key: "fecha_recepcion",
    },
    {
      label: "Hora Recepción",
      key: "hora_recepcion",
    },
    {
      label: "Tipo Movimiento",
      key: "descripcion_estado",
    },
    {
      label: "Fecha Despacho",
      key: "fecha_salida",
    },
    {
      label: "Nª Contenedor",
      key: "numero_contenedor",
    },
    {
      label: "Cliente",
      key: "razon_social_cliente",
    },
    {
      label: "Nª Guía",
      key: "numero_guia",
    },
    {
      label: "Acciones",
      key: "actions",
    },
  ];

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const filteredItems = useMemo(() => {
    let filterValues = [...values];

    if (filterValue.numero_contenedor !== "") {
      filterValues = filterValues.filter((c) =>
        c.numero_contenedor
          .toUpperCase()
          .includes(filterValue.numero_contenedor.toUpperCase())
      );
    }

    if (filterValue.numero_guia !== "") {
      filterValues = filterValues.filter((c) =>
        c.numero_guia
          .toUpperCase()
          .includes(filterValue.numero_guia.toUpperCase())
      );
    }

    // filtrar desde hasta fecha
    if (
      filterValue.fecha_recepcion_desde !== "" &&
      filterValue.fecha_recepcion_hasta !== ""
    ) {
      filterValues = filterValues.filter((item) => {
        const itemDate = new Date(item.fecha_recepcion);
        const startDate = new Date(filterValue.fecha_recepcion_desde);
        const endDate = new Date(filterValue.fecha_recepcion_hasta);
        return itemDate >= startDate && itemDate <= endDate;
      });
    } else if (
      filterValue.fecha_recepcion_desde !== "" &&
      filterValue.fecha_recepcion_hasta === ""
    ) {
      filterValues = filterValues.filter((item) => {
        const itemDate = new Date(item.fecha_recepcion);
        const startDate = new Date(filterValue.fecha_recepcion_desde);
        return itemDate >= startDate;
      });
    } else if (
      filterValue.fecha_recepcion_desde === "" &&
      filterValue.fecha_recepcion_hasta !== ""
    ) {
      filterValues = filterValues.filter((item) => {
        const itemDate = new Date(item.fecha_recepcion);
        const startDate = new Date(filterValue.fecha_recepcion_desde);
        const endDate = new Date(filterValue.fecha_recepcion_hasta);
        return itemDate <= endDate;
      });
    }

    return filterValues;
  }, [values, filterValue]);

  const items = useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;

    return filteredItems.slice(start, end);
  }, [page, filteredItems]);

  const onRowsPerPageChange = useCallback((e) => {
    setRowsPerPage(Number(e.target.value));
    setPage(1);
  }, []);

  const detailMov = useRef([]);

  const onOpenEye = (value) => {
    console.log(value);

    if (openEye === true) {
      setOpenEye(!openEye);
    } else {
      setOpenEye(!openEye);
      detailMov.current = value;
    }
    console.log(detailMov);
  };

  const tipoMovColorMap = {
    STOCK: "success",
    DESPACHADO: "warning",
  };

  const renderCell = useCallback((user, columnKey) => {
    const cellValue = user[columnKey];

    switch (columnKey) {
      case "descripcion_estado":
        return (
          <Chip
            className="capitalize "
            color={tipoMovColorMap[user.descripcion_estado]}
            size="sm"
            variant="flat"
          >
            {cellValue}
          </Chip>
        );
      case "actions":
        return (
          <div className="relative flex items-center gap-2">
            <Button
              isIconOnly
              onClick={() => onOpenEye(user)}
              className="text-lg w-1 h-5 bg-transparent text-slate-500 cursor-pointer active:opacity-50"
              aria-label="Like"
            >
              <EyeIcon />
            </Button>
          </div>
        );
      default:
        return cellValue;
    }
  }, []);

  const handleChangeFilter = (e) => {
    console.log(e.target.value);
    setFilterValue({ ...filterValue, [e.target.name]: e.target.value });
  };

  const onClear1 = () => {
    setFilterValue({ ...filterValue, numero_contenedor: "" });
  };

  const onClear2 = () => {
    setFilterValue({ ...filterValue, numero_guia: "" });
  };

  const onClear3 = () => {
    setFilterValue({ ...filterValue, fecha_recepcion_desde: "" });
  };

  const onClear4 = () => {
    setFilterValue({ ...filterValue, fecha_recepcion_hasta: "" });
  };

  const topContent = useMemo(() => {
    return (
      <div className="grid gap-4 grid-cols-4">
        {/* {JSON.stringify(filterValue)} */}
        <Input
          isClearable
          placeholder="Buscar contenedor"
          size="sm"
          startContent={<MagnifyingGlassIcon className="h-6" />}
          name="numero_contenedor"
          value={filterValue.numero_contenedor}
          onClear={() => onClear1()}
          onChange={handleChangeFilter}
        />

        <Input
          isClearable
          placeholder="Buscar Nº guia"
          size="sm"
          startContent={<MagnifyingGlassIcon className="h-6" />}
          name="numero_guia"
          value={filterValue.numero_guia}
          onClear={() => onClear2()}
          onChange={handleChangeFilter}
        />

        <Input
          isClearable
          type="date"
          size="sm"
          label="Fecha Desde"
          labelPlacement="outside-left"
          name="fecha_recepcion_desde"
          value={filterValue.fecha_recepcion_desde}
          onClear={() => onClear3()}
          onChange={handleChangeFilter}
        />

        <Input
          isClearable
          type="date"
          size="sm"
          label="Fecha Hasta"
          labelPlacement="outside-left"
          name="fecha_recepcion_hasta"
          value={filterValue.fecha_recepcion_hasta}
          onClear={() => onClear4()}
          onChange={handleChangeFilter}
        />
      </div>
    );
  }, [filterValue, handleChangeFilter, onClear1, onClear2, onClear3, onClear4]);

  const pages = Math.ceil(filteredItems.length / rowsPerPage);

  return pages ? (
    <>
      <Modal
        scrollBehavior="inside"
        size="5xl"
        isOpen={openEye}
        onOpenChange={onOpenEye}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                <div className="flex flex-col">
                  <p className="text-md">
                    Movimiento Nº {detailMov.current.id} -{" "}
                    {detailMov.current.descripcion_estado} -{" "}
                    <Chip className="bg-indigo-500 text-white">
                      {" "}
                      Ingresado el {detailMov.current.fecha_recepcion}
                    </Chip>
                  </p>
                </div>
              </ModalHeader>
              <ModalBody>
                <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-10  pt-4">
                  <div className="flex w-full col-span-1 flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                    <Button className="w-full" size="sm" color="primary">
                      Cliente
                    </Button>
                  </div>
                  <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                    <Input
                      type="text"
                      label="RUT"
                      size="sm"
                      labelPlacement="outside"
                      value={detailMov.current.rut_cliente}
                      variant="bordered"
                      name="numero_contenedor"
                    />
                  </div>
                  <div className="flex w-full col-span-1 flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                    <Input
                      type="text"
                      label="Razón Social"
                      size="sm"
                      labelPlacement="outside"
                      value={detailMov.current.razon_social_cliente}
                      variant="bordered"
                      name="numero_contenedor"
                    />
                  </div>
                  <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                    <Input
                      type="text"
                      label="Dirección"
                      labelPlacement="outside"
                      size="sm"
                      value={detailMov.current.direccion_cliente}
                      variant="bordered"
                      name="numero_contenedor"
                    />
                  </div>
                  {detailMov.current.razon_social_subcliente !== null && (
                    <>
                      <div className="flex w-full col-span-1 flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                        <Button size="sm" className="w-full" color="primary">
                          SubCliente
                        </Button>
                      </div>
                      <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                        <Input
                          type="text"
                          label="RUT"
                          size="sm"
                          labelPlacement="outside"
                          value={detailMov.current.rut_subcliente}
                          variant="bordered"
                          name="numero_contenedor"
                        />
                      </div>
                      <div className="flex w-full col-span-1 flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                        <Input
                          type="text"
                          label="Razón Social"
                          labelPlacement="outside"
                          value={detailMov.current.razon_social_subcliente}
                          variant="bordered"
                          name="numero_contenedor"
                          size="sm"
                        />
                      </div>
                      <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                        <Input
                          type="text"
                          label="Dirección "
                          size="sm"
                          labelPlacement="outside"
                          value={detailMov.current.direccion_subcliente}
                          variant="bordered"
                          name="numero_contenedor"
                        />
                      </div>
                    </>
                  )}
                  <div className="flex w-full col-span-4 flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                    <hr />
                  </div>
                  <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                    <Input
                      type="text"
                      label="Nª de Contenedor"
                      labelPlacement="outside"
                      value={detailMov.current.numero_contenedor}
                      variant="bordered"
                      name="numero_contenedor"
                      size="sm"
                    />
                  </div>
                  <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                    <Input
                      type="text"
                      label="Tipo Contenedor"
                      labelPlacement="outside"
                      value={detailMov.current.dimension}
                      variant="bordered"
                      name="numero_contenedor"
                      size="sm"
                    />
                  </div>
                  <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                    <Input
                      type="text"
                      label="Contenedor"
                      labelPlacement="outside"
                      value={detailMov.current.descripcion_tipo_contenedor}
                      variant="bordered"
                      name="numero_contenedor"
                      size="sm"
                    />
                  </div>
                  <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                    <Input
                      type="text"
                      label="Conectado"
                      labelPlacement="outside"
                      value={detailMov.current.conexion}
                      variant="bordered"
                      size="sm"
                      name="numero_contenedor"
                    />
                  </div>

                  <div className="flex  w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                    <Input
                      type="text"
                      label="Nª de Sello"
                      labelPlacement="outside"
                      value={detailMov.current.numero_sello}
                      variant="bordered"
                      size="sm"
                      name="numero_contenedor"
                    />
                  </div>

                  <div className="flex w-full  flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                    <Input
                      type="text"
                      size="sm"
                      label="Nª de Guía"
                      labelPlacement="outside"
                      value={detailMov.current.numero_guia}
                      variant="bordered"
                      name="numero_contenedor"
                    />
                  </div>

                  <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                    <Switch
                      defaultSelected
                      isSelected={detailMov.current.refeer}
                    >
                      Refeer
                    </Switch>
                  </div>
                  <div className="flex w-full  flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                    <Switch
                      defaultSelected
                      isSelected={detailMov.current.carga_peligrosa}
                    >
                      Carga Peligrosa
                    </Switch>
                  </div>
                  <div className="flex w-full col-span-4 flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                    <hr />
                  </div>
                  {detailMov.current.estado === "STOCK" ? (
                    <>
                      <div className="flex w-full  flex-wrap items-end  col-span-2 md:flex-nowrap mb-6 md:mb-0 gap-4">
                        <Input
                          type="text"
                          label="Conductor Ingreso"
                          labelPlacement="outside"
                          value={detailMov.current.nombre_conductor_entrada}
                          variant="bordered"
                          size="sm"
                          name="numero_contenedor"
                        />
                      </div>
                      <div className="flex w-full  flex-wrap  col-span-2 items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                        <Input
                          type="text"
                          label="Placa Patente Ingreso"
                          labelPlacement="outside"
                          value={detailMov.current.placa_patente_entrada}
                          variant="bordered"
                          size="sm"
                          name="numero_contenedor"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex w-full  flex-wrap  items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                        <Input
                          type="text"
                          label="Conductor Ingreso"
                          labelPlacement="outside"
                          size="sm"
                          value={detailMov.current.nombre_conductor_entrada}
                          variant="bordered"
                          name="numero_contenedor"
                        />
                      </div>
                      <div className="flex w-full  flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                        <Input
                          type="text"
                          label="Placa Patente Ingreso"
                          labelPlacement="outside"
                          size="sm"
                          value={detailMov.current.placa_patente_entrada}
                          variant="bordered"
                          name="numero_contenedor"
                        />
                      </div>
                      <div className="flex w-full  flex-wrap  items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                        <Input
                          type="text"
                          label="Conductor Salida"
                          size="sm"
                          labelPlacement="outside"
                          value={detailMov.current.nombre_conductor_salida}
                          variant="bordered"
                          name="numero_contenedor"
                        />
                      </div>
                      <div className="flex w-full  flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                        <Input
                          type="text"
                          size="sm"
                          label="Placa Patente Salida"
                          labelPlacement="outside"
                          value={detailMov.current.placa_patente_salida}
                          variant="bordered"
                          name="numero_contenedor"
                        />
                      </div>
                    </>
                  )}
                </div>
              </ModalBody>
              <ModalFooter>
                <Button className="w-full" color="danger" onPress={onOpenEye}>
                  Cerrar
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      <Table
        topContent={topContent}
        aria-label="Example table with dynamic content"
        bottomContent={
          <div className="flex w-full justify-center">
            <Pagination
              isCompact
              showControls
              showShadow
              color="secondary"
              page={page}
              total={pages}
              onChange={(page) => setPage(page)}
            />
          </div>
        }
      >
        <TableHeader columns={columns}>
          {(column) => (
            <TableColumn key={column.key}>{column.label}</TableColumn>
          )}
        </TableHeader>
        <TableBody
          emptyContent={"No rows to display."}
          isLoading={loading}
          loadingContent={<Spinner label="Loading..." />}
          items={items}
        >
          {(item) => (
            <TableRow key={item.key}>
              {(columnKey) => (
                <TableCell>{renderCell(item, columnKey)}</TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  ) : (
    <Table
      topContent={topContent}
      aria-label="Example table with dynamic content"
      bottomContent={
        <div className="flex w-full justify-center">
          <Pagination
            isCompact
            showControls
            showShadow
            color="secondary"
            page={page}
            total={pages}
            onChange={(page) => setPage(page)}
          />
        </div>
      }
    >
      <TableHeader columns={columns}>
        {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
      </TableHeader>
      <TableBody
        isLoading={loading}
        emptyContent={"No hay datos para mostrar."}
        loadingContent={<Spinner label="Loading..." />}
        items={items}
      >
        {(item) => (
          <TableRow key={item.key}>
            {(columnKey) => (
              <TableCell>{renderCell(item, columnKey)}</TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default MovimientosTableAdmin;
