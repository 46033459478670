import React from 'react'


import DataTable from 'react-data-table-component'



const EstadosTable = ({values,handleModal}) => {


    const columns = [
        {
          name: 'ID',
          selector: row => row.id,
          width:'60px'
        },
        {
            name: 'Estado',
            selector: row => row.descripcion
        }
    ]


    const data = values && values.map( c => {
        return {
            id:c.id,
            descripcion:c.descripcion,
            
        }
    })

  return (
    <DataTable
    data={data}
    columns={columns}
    pagination
    />
  )
}

export default EstadosTable