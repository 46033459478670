import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom'
import { ChevronDown } from '../../img/ChevronArrow.jsx';
import { Navbar, NavbarBrand, NavbarMenuToggle, NavbarMenu,Image, NavbarMenuItem, NavbarContent, NavbarItem, Button, DropdownItem, DropdownTrigger, Dropdown, DropdownMenu } from "@nextui-org/react";

const AdminNav = () => {

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    let navigate = useNavigate()

    // let { user } = useSelector((state) => ({ ...state }))



    const dispatch = useDispatch()

    const logout = () => {
        dispatch({
            type: 'LOGOUT',
            payload: null
        })
        navigate('/')
    }

    const menuItems = [
        {
            title: 'Usuarios',
            to: '/admin/users'
        }
    ]

    const datosMaestrosItems = [
        // {
        //     title: 'Almacenes',
        //     to: '/admin/almacenes'
        // },
        {
            title: 'Tipo contenedores',
            to: '/admin/tipo-contenedores'
        },
        {
            title: 'Flota',
            to: '/admin/flota'
        },
        {
            title: 'Clientes',
            to: '/admin/clientes'
        },
        {
            title: 'Conductores',
            to: '/admin/choferes'
        }
    ];


    const movimientosItems = [
        {
            title: 'Movimientos',
            to: '/admin/movimientos',
            description: 'Registro de movimientos ingresados'
        },
        {
            title: 'Aprobaciones',
            to: '/admin/aprobaciones',
            description: 'Aprobar peticiones de edición'
        },
        {
            title: 'Reporte',
            to: '/admin/reporte',
            description: 'Reporte de movimientos'
        }
    ];


    return (
        <Navbar 
        onMenuOpenChange={setIsMenuOpen}
        >
            <NavbarContent>
                <NavbarMenuToggle
                    aria-label={isMenuOpen ? "Close menu" : "Open menu"}
                    className="sm:hidden"
                />
                <NavbarBrand>
                <Image
                    alt="nextui logo"
                    height={40}
                    radius="sm"
                    src="https://avatars.githubusercontent.com/u/86160567?s=200&v=4"
                    width={40}
                />
                    <p className="font-bold text-inherit ml-2">Transportes Muñoz</p>
                </NavbarBrand>
            </NavbarContent>
            <NavbarContent className="hidden sm:flex gap-4 " justify="center">

                {menuItems.map(c => (
                    <NavbarItem>
                        <Button className='bg-gray-100 text-slate-700' onClick={() => navigate(c.to)} >
                            {c.title}
                        </Button>
                    </NavbarItem>
                ))}
                <Dropdown>

                    <NavbarItem>
                        <DropdownTrigger>
                            <Button
                                disableRipple
                                className=" bg-transparent bg-gray-100 text-slate-700 data-[hover=true]:bg-transparent"
                                endContent={<ChevronDown fill="currentColor" size={16} />}
                                radius="sm"
                                variant="light"
                            >
                                Datos Maestros
                            </Button>
                        </DropdownTrigger>
                    </NavbarItem>
                    <DropdownMenu
                        aria-label="ACME features"
                        className="w-[340px]"
                        itemClasses={{
                            base: "gap-4",
                        }}
                    >
                        {datosMaestrosItems && datosMaestrosItems.map(c => (
                            <DropdownItem
                                onClick={() => navigate(c.to)}

                                description={c.description}
                            >
                                <Link to={c.to} className="hover:no-underline text-slate-800" color="foreground " >
                                    {c.title}
                                </Link>
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </Dropdown>
                <Dropdown>

                    <NavbarItem>
                        <DropdownTrigger>
                            <Button
                                disableRipple
                                className=" bg-transparent bg-gray-100 text-slate-700 data-[hover=true]:bg-transparent"
                                endContent={<ChevronDown fill="currentColor" size={16} />}
                                radius="sm"
                                variant="light"
                            >
                                Movimientos
                            </Button>
                        </DropdownTrigger>
                    </NavbarItem>
                    <DropdownMenu
                        aria-label="ACME features"
                        className="w-[340px]"
                        itemClasses={{
                            base: "gap-4",
                        }}
                    >
                        {movimientosItems && movimientosItems.map(c => (
                            <DropdownItem
                                onClick={() => navigate(c.to)}

                                description={c.description}
                            >
                                <Link to={c.to} className="hover:no-underline text-slate-800" color="foreground " >
                                    {c.title}
                                </Link>
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </Dropdown>

            </NavbarContent>
            <NavbarContent justify="end">
                <NavbarItem>
                    <Button onClick={logout} className='bg-slate-700 text-white hover:bg-slate-500 transition-all' href="#" variant="flat">
                        Desconectarse
                    </Button>
                </NavbarItem>
            </NavbarContent>
            <NavbarMenu>




                {datosMaestrosItems.map((c, index) => (
                    <NavbarMenuItem key={`${c}-${index}`}>
                        <Link
                            color={
                                index === 2 ? "primary" : index === datosMaestrosItems.length - 1 ? "danger" : "foreground"
                            }
                            className="w-full"
                            to={c.to}
                            size="lg"
                        >
                            {c.title}
                        </Link>
                    </NavbarMenuItem>
                ))}
            </NavbarMenu>
        </Navbar>
       

    )
}

export default AdminNav