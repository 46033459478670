import axios from "axios";




export const getAlmacenes = async(token,page,per_page) =>{
    return await axios.get(`${process.env.REACT_APP_API}/users/almacenes?page=${page}&per_page=${per_page}`,{
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
}


export const createAlmacen = async(token,body) =>{
    return await axios.post(`${process.env.REACT_APP_API}/users/create_almacen`,body,{
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
}

export const updateAlmacen = async(token,id,body) =>{
    return await axios.put(`${process.env.REACT_APP_API}/users/update_almacen/${id}`,body,{
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
}