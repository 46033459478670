import React, { useState,useEffect } from 'react'
import { Input } from "@nextui-org/react";
import Loading from '../components/others/loading'

import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector  } from 'react-redux';
import { userLogin,getCurrentUser } from '../functions/api/auth'
import { MailIcon } from '../img/MailIcon';
import { EyeFilledIcon } from "../img/EyeFilledIcon";
import { EyeSlashFilledIcon } from "../img/EyeSlashFilledIcon";


const loginState = {
    email: '',
    password: ''
}

const Login = () => {

    const [isVisible, setIsVisible] = useState(false);
    const [loading,setLoading] = useState(false)
    const [values, setValues] = useState(loginState)

    let navigate = useNavigate()
    let dispatch = useDispatch()
  

    const toggleVisibility = () => setIsVisible(!isVisible);
    
    const {user} = useSelector((state)=>({...state}))

    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
    }

    const { email, password } = values


    useEffect(() =>{
        if(user && user.token) navigate('/')
    },[dispatch])


    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        userLogin({email:email,contrasena:password})
        .then(res =>{
            localStorage.setItem('token', res.data.access_token);

            getCurrentUser({token:res.data.access_token}).then(r => {
                dispatch({
                    type:'LOGGED_IN_USER',
                    payload:{
                      id:r.data.id,
                      email:r.data.email,
                      nombre_completo:r.data.nombre_completo,
                      role:r.data.role,
                      token: res.data.access_token
                    },
                })
                
                toast.success(`Inicio de sesión correcto`)
               
                setLoading(false)

                if(r.data.role === 'admin'){
                    navigate(`/admin/users`)
                } else if (r.data.role === 'operator'){
                    navigate(`/operador/registro-movimientos`)
                }  else if (r.data.role === 'user'){
                    navigate(`/user/home`)
                } 
            }
        )      
        })
        .catch(
            err => {
                toast.error('No se ha podido iniciar sesión')
                console.log(err)
                setLoading(false)
            }
        )
    };

  return (

    loading ? (
        <Loading />
    ):(
 
    <div className='flex flex-col items-center justify-center min-h-screen py-1 bg-gray-100'>

            <div className='flex flex-col items-center w-full pt-5 flex-1 text-center'>
                <div className='bg-white rounded-2xl shadow-2xl grid grid-cols-2  max-w-4xl'>
                    <div className=' col-span-2 lg:col-span-1  w-full p-5 lg:w-full'>
                        <div className='text-left font-bold'>
                            <span className='text-sky-700'><i class="fa-solid fa-truck"></i> Transportes </span>Muñoz
                        </div>
                        <div className='py-10'>
                            <h2 className='text-3xl fpmt-bold text-sky-700  mb-2'>Iniciar Sesión</h2>
                            <div className='border-2 w-10 border-sky-700 inline-block mb-2'></div>
                            <div className='flex flex-col items-center'>
                                <Input
                                    type="email"
                                    label="Correo electrónico"
                                    variant="bordered"
                                    defaultValue="mail@pisicultura.org"
                                    className="max-w-xs mb-4"
                                    name='email'
                                    value={email}
                                    onChange={handleChange}
                                    startContent={
                                        <MailIcon className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                                    }
                                />
                                <Input
                                    label="Contraseña"
                                    name='password'
                                    value={password}
                                    onChange={handleChange}
                                    variant="bordered"
                                    placeholder="Enter your password"
                                    endContent={
                                        <button className="focus:outline-none" type="button" onClick={toggleVisibility}>
                                            {isVisible ? (
                                                <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                                            ) : (
                                                <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                                            )}
                                        </button>
                                    }
                                    type={isVisible ? "text" : "password"}
                                    className="max-w-xs"
                                />
                                <button onClick={handleSubmit} className='border-2 bg-sky-700 text-white  mt-2 rounded-full px-12 transition-all py-2 inline-block font-semibold hover:bg-white hover:text-sky-700 hover:border-sky-700'>Ingresar</button>

                              <a href='https://bidaten.com/' className='text-gray-300 text-sm mt-10'>Desarrollado por @Bidaten Ltda</a>
                            </div>
                        </div>
                    </div>
                    <div className='w-full  md:col-span-1 lg:block hidden   bg-sky-700 text-white rounded-tr-2xl rounded-br-2xl py-36 px-12  '>
                        <h2 className='text-3xl  font-bold mb-2'>¡Bienvenido!</h2>
                        <div className='border-2 w-10 border-white inline-block mb-2'></div>
                        <p className='mb-2'>Esta ingresando a sistema de control de contenedores</p>
                    </div>
                </div>
            </div>
        </div>
    )
  )
}

export default Login