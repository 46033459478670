import React, { useCallback, useMemo, useState } from 'react'
import {
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    Spinner,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    useDisclosure,
    TableCell,
    Pagination,
    Chip,
    Input,
    Switch,
    Select,
    SelectItem
} from "@nextui-org/react";

import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import ModalCamion from '../../pages/operador/ModalCamion'
import ModalConductor from '../../pages/operador/ModalConductor'


const filtersInitialState = {
    numero_contenedor: '',
    numero_guia: '',
    fecha_recepcion_desde: '',
    fecha_recepcion_hasta: ''
}

const StockTable = ({ data, loading, updateData, choferes, handleChange, flota, detailMov, handleSubmit, loadChoferes, loadFlota }) => {

    const [modalCamion, setModalCamion] = useState(false)
    const [modalConductor, setModalConductor] = useState(false)

    const openModalCamion = () => {
        setModalCamion(!modalCamion)
    }

    const openModalConductor = () => {
        setModalConductor(!modalConductor)
    }

    const columns = [
        {
            label: 'ID',
            key: 'id',
        },
        {
            label: 'Recepción',
            key: 'fecha_recepcion'
        },
        {
            label: 'Hora Recepción',
            key: 'hora_recepcion'
        },
        {
            label: 'Nª Contenedor',
            key: 'numero_contenedor'
        },
        // {
        //     label: 'Nª Sello',
        //     key: 'numero_sello'
        // },
        {
            label: 'Nª Guía',
            key: 'numero_guia'
        },
        {
            label: 'Tipo Movimiento',
            key: 'descripcion_estado'
        },
        {
            label: 'Acciones',
            key: 'actions'
        }
    ]

    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filterValue, setFilterValue] = useState(filtersInitialState);

    const onRowsPerPageChange = useCallback((e) => {
        setRowsPerPage(Number(e.target.value));
        setPage(1);
    }, []);

    const filteredItems = useMemo(() => {
        let filterValues = [...data];

        if (filterValue.numero_contenedor !== '') {
            filterValues = filterValues.filter((c) =>
                c.numero_contenedor.toUpperCase().includes(filterValue.numero_contenedor.toUpperCase()),
            );
        }

        if (filterValue.numero_guia !== '') {
            filterValues = filterValues.filter((c) =>
                c.numero_guia.toUpperCase().includes(filterValue.numero_guia.toUpperCase()),
            );
        }

        // filtrar desde hasta fecha
        if (filterValue.fecha_recepcion_desde !== '' && filterValue.fecha_recepcion_hasta !== '') {
            filterValues = filterValues.filter(item => {
                const itemDate = new Date(item.fecha_recepcion);
                const startDate = new Date(filterValue.fecha_recepcion_desde);
                const endDate = new Date(filterValue.fecha_recepcion_hasta);
                return itemDate >= startDate && itemDate <= endDate;
            })
        } else if (filterValue.fecha_recepcion_desde !== '' && filterValue.fecha_recepcion_hasta === '') {
            filterValues = filterValues.filter(item => {
                const itemDate = new Date(item.fecha_recepcion);
                const startDate = new Date(filterValue.fecha_recepcion_desde);
                return itemDate >= startDate
            })
        } else if (filterValue.fecha_recepcion_desde === '' && filterValue.fecha_recepcion_hasta !== '') {
            filterValues = filterValues.filter(item => {
                const itemDate = new Date(item.fecha_recepcion);
                const startDate = new Date(filterValue.fecha_recepcion_desde);
                const endDate = new Date(filterValue.fecha_recepcion_hasta);
                return itemDate <= endDate;
            })
        }


        return filterValues;
    }, [data, filterValue]);

    const items = useMemo(() => {
        const start = (page - 1) * rowsPerPage;
        const end = start + rowsPerPage;

        return filteredItems.slice(start, end);
    }, [page, filteredItems]);



    const handleChangeFilter = (e) => {
        console.log(e.target.value)
        setFilterValue({ ...filterValue, [e.target.name]: e.target.value })
    }

    const onClear1 = () => {
        setFilterValue({ ...filterValue, numero_contenedor: '' })
    }

    const onClear2 = () => {
        setFilterValue({ ...filterValue, numero_guia: '' })
    }


    const onClear3 = () => {
        setFilterValue({ ...filterValue, fecha_recepcion_desde: '' })
    }

    const onClear4 = () => {
        setFilterValue({ ...filterValue, fecha_recepcion_hasta: '' })
    }

    const topContent = useMemo(() => {
        return (
            <div className="grid gap-4 grid-cols-4">

                <Input
                    isClearable
                    placeholder="Buscar contenedor"
                    size='sm'
                    startContent={<MagnifyingGlassIcon className='h-6' />}
                    name='numero_contenedor'
                    value={filterValue.numero_contenedor}
                    onClear={() => onClear1()}
                    onChange={handleChangeFilter}
                />

                <Input
                    isClearable
                    placeholder="Buscar Nº guia"
                    size='sm'
                    startContent={<MagnifyingGlassIcon className='h-6' />}
                    name='numero_guia'
                    value={filterValue.numero_guia}
                    onClear={() => onClear2()}
                    onChange={handleChangeFilter}
                />

                <Input
                    isClearable
                    type='date'
                    size='sm'
                    label='Fecha Desde'
                    labelPlacement='outside-left'
                    name='fecha_recepcion_desde'
                    value={filterValue.fecha_recepcion_desde}
                    onClear={() => onClear3()}
                    onChange={handleChangeFilter}
                />

                <Input
                    isClearable
                    type='date'
                    size='sm'
                    label='Fecha Hasta'
                    labelPlacement='outside-left'
                    name='fecha_recepcion_hasta'
                    value={filterValue.fecha_recepcion_hasta}
                    onClear={() => onClear4()}
                    onChange={handleChangeFilter}
                />
            </div>
        );
    }, [
        filterValue,
        handleChangeFilter,
        onClear1,
        onClear2,
        onClear3,
        onClear4
    ]);

    const pages = Math.ceil(filteredItems.length / rowsPerPage);


    const tipoMovColorMap = {
        STOCK: "success"
    };



    const [open, setOpen] = useState(false)


    const onOpen = (value) => {
        console.log(value)

        if (open === true) {
            setOpen(!open)
        } else {
            setOpen(!open)
            detailMov.current = value
        }
    }

    const renderCell = useCallback((user, columnKey) => {
        const cellValue = user[columnKey];

        switch (columnKey) {
            case "descripcion_estado":
                return (
                    <Chip className="capitalize " color={tipoMovColorMap[user.descripcion_estado]} size="sm" variant="flat">
                        {cellValue}
                    </Chip>
                );
            case 'actions':
                return (

                    <Button radius='full' onClick={() => onOpen(user)} className='bg-sky-500 text-white hover:text-sky-500 hover:bg-sky-200  transition-all text-xs' size='sm' aria-label="Like">
                        <i class="fa-solid fa-truck"></i>
                        DESPACHAR
                    </Button>
                )
            default:
                return cellValue;
        }
    }, []);

    const { chofer_id_salida, camion_id_salida, fecha_egreso } = updateData

    return (
        pages ? (
            <>

                <ModalCamion isOpen={modalCamion} onOpenChange={openModalCamion} setOpen={setModalCamion} loadChoferes={loadChoferes} loadFlota={loadFlota} />
                <ModalConductor isOpen={modalConductor} onOpenChange={openModalConductor} setOpen={setModalConductor} loadChoferes={loadChoferes} loadFlota={loadFlota} />
                <Modal
                    scrollBehavior='outside'
                    size='5xl'
                    isOpen={open}
                    onOpenChange={onOpen}>
                    <ModalContent>
                        {(onClose) => (
                            <>
                                <ModalHeader className="flex flex-col gap-1">

                                    <div className="flex flex-col">
                                        <p className="text-md">Movimiento Nº {detailMov.current.id} - {detailMov.current.descripcion_estado}</p>
                                        <Chip className='bg-indigo-500 text-white'>Ingresado el  {detailMov.current.fecha_recepcion}</Chip>
                                    </div>
                                </ModalHeader>
                                <ModalBody>
                                    <div className='grid lg:grid-cols-3 md:grid-cols-3 gap-10 mt-2' >


                                        <div className="flex flex-col">
                                            <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">

                                                <Input
                                                    type="datetime-local"
                                                    label="Fecha Salida"
                                                    labelPlacement='outside'
                                                    placeholder="Fecha Salida"
                                                    value={fecha_egreso}
                                                    variant='bordered'
                                                    // className='uppercase'
                                                    name='fecha_egreso'
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex flex-col">
                                            <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">

                                                <Select
                                                    items={flota}
                                                    label="Camión"
                                                    labelPlacement='outside'
                                                    placeholder="Seleccionar Camión"
                                                    variant='bordered'
                                                    value={camion_id_salida}
                                                    name='camion_id_salida'
                                                    onChange={handleChange}
                                                >
                                                    {(flota) => <SelectItem key={flota.id}>{flota.placa_patente}</SelectItem>}
                                                </Select>
                                                <Button onClick={() => openModalCamion()} className='bg-foreground text-white justify-start w-full' size='sm'><span className='font-bold text-xl'>+</span> Agregar Camión</Button>
                                            </div>
                                        </div>

                                        <div className="flex flex-col">
                                            <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-1 inline-block">
                                                <label className='font-semibold text-sm'>Conductor</label>
                                                <div className='w-full flex flex-col'>
                                                    <div className='relative w-full inline-flex tap-highlight-transparent flex-row items-center shadow-sm px-3  border-medium border-default-200 data-[hover=true]:border-default-400 group-data-[focus=true]:border-foreground h-unit-10 min-h-unit-10 rounded-medium transition-background !duration-150 transition-colors motion-reduce:transition-none'>
                                                        <select
                                                            value={chofer_id_salida}
                                                            name='chofer_id_salida'
                                                            onChange={handleChange}
                                                            className='w-full'
                                                        >
                                                            <option>Seleccionar Conductor</option>
                                                            {choferes && choferes.map(c => (
                                                                <option value={c.id}>{c.nombre_completo}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                        <Button onClick={() => openModalConductor()} className='bg-foreground text-white justify-start w-full mt-3' size='sm'><span className='font-bold text-xl'>+</span> Agregar Conductor</Button>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <Button className='w-full' color="danger" onPress={onOpen}>
                                        Cerrar
                                    </Button>
                                    <Button className='w-full text-white' color="success" onClick={handleSubmit}>
                                        Guardar
                                    </Button>
                                </ModalFooter>
                            </>
                        )}
                    </ModalContent>
                </Modal>
                <Table
                    topContent={topContent}
                    aria-label="Example table with dynamic content"
                    bottomContent={
                        <div className="flex w-full justify-between">
                            <Pagination
                                isCompact
                                showControls
                                showShadow
                                color="secondary"
                                page={page}
                                total={pages}
                                onChange={(page) => setPage(page)}
                            />
                            <label className="flex items-center text-default-400 text-small">
                                Filas por página:
                                <select
                                    className="bg-transparent outline-none text-default-400 text-small"
                                    onChange={onRowsPerPageChange}
                                >
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                </select>
                            </label>
                        </div>
                    }

                >

                    <TableHeader columns={columns}>
                        {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
                    </TableHeader>
                    <TableBody
                        isLoading={loading}
                        loadingContent={<Spinner label="Loading..." />} items={items}>
                        {(item) => (
                            <TableRow key={item.key}>
                                {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </>
        ) : (
            <Table
                isCompact
                topContent={topContent}
                aria-label="Example table with dynamic content"
                emptyContent={"No hay datos para mostrar."}
                bottomContent={
                    <div className="flex w-full justify-center">
                        <Pagination
                            isCompact
                            showControls
                            showShadow
                            color="secondary"
                            page={page}
                            total={pages}
                            onChange={(page) => setPage(page)}
                        />
                    </div>
                }

            >

                <TableHeader columns={columns}>
                    {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
                </TableHeader>
                <TableBody
                    isLoading={loading}
                    loadingContent={<Spinner label="Loading..." />} items={items}>
                    {(item) => (
                        <TableRow key={item.key}>
                            {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        )
    )
}

export default StockTable