import React from 'react'


import DataTable from 'react-data-table-component'
import { formatRut } from '../../functions/utilities/format'

const ClientesTable = ({values,handleModal,handleModalSubcliente,handleModalSubclienteAdd}) => {


    const columns = [
        {
          name: 'ID',
          selector: row => row.id,
          width:'60px'
        },
        {
            name: 'RUT',
            selector: row => row.rut
        },
        {
            name: 'Razón Social',
            selector: row => row.razon_social
        },
        {
            name: 'Dirección',
            selector: row => row.direccion
        },
        {
            name: 'Contacto',
            selector: row => row.contacto
        },
        {
            name: 'Sub-Clientes',
            selector: row => row.subclientes
        },
        {
            name: 'Agregar Sub-Cliente',
            selector: row => row.subclientesAdd
        },
        {
            name: 'Detalle',
            selector: row => row.detalle
        }
    ]


    const data = values && values.map( c => {
        return {
            id:c.id,
            rut:formatRut(c.rut),
            razon_social:c.razon_social,
            direccion:c.direccion,
            contacto: c.contacto,
            subclientes:<button className='btn btn-info' onClick={handleModalSubcliente} value={c.id}><i class="fa-solid fa-magnifying-glass"></i> Detalle</button>,
            subclientesAdd:<button className='btn btn-success' onClick={handleModalSubclienteAdd} value={c.id}><i class="fa-solid fa-plus"></i> agregar</button>,
            detalle: <button onClick={handleModal} value={c.id} className='btn btn-primary'> <i class="fa-solid fa-pen-to-square"></i> Editar</button>
            
        }
    })

  return (
    <DataTable
    data={data}
    columns={columns}
    pagination
    />
  )
}

export default ClientesTable