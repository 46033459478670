import React from 'react'
import { formatRut } from '../../../functions/utilities/format'

const SubclienteCreate = ({ handleModalSubclienteAdd, handleSubclienteCreate, handleSubclienteChange, subClientes }) => {

    const { rut, razon_social, email, direccion, contacto } = subClientes

    return (
        <>
            <div className='modalBackground' onClick={handleModalSubclienteAdd}>
            </div>
            <div className='modalCard'>
                <div className="row">
                    <div className="col-md-12">
                        <p className='modalTitle'>CREAR SUBCLIENTE</p>
                    </div>
                    <div className="col-md-12 mb-4">
                        <hr className='bg-blue' />
                    </div>
                    <div className="col-md-2 ">
                        <label className='label-title'>Rut</label>
                        <br />
                        <input className='loginInput' value={formatRut(rut)} name='rut' maxLength='12' onChange={handleSubclienteChange} />
                    </div>

                    <div className="col-md-2 ">
                        <label className='label-title'>Razón Social</label>
                        <br />
                        <input className='loginInput' value={razon_social} name='razon_social' onChange={handleSubclienteChange} />
                    </div>
                    <div className="col-md-2 ">
                        <label className='label-title'>Email</label>
                        <br />
                        <div class="input-container">
                            <input className='loginInput' value={email} name='email' onChange={handleSubclienteChange} />
                            <i class="fa-solid fa-envelope icon" />
                        </div>
                    </div>

                    <div className="col-md-3 ">
                        <label className='label-title'>Dirección</label>
                        <br />
                        <input className='loginInput' value={direccion} name='direccion' onChange={handleSubclienteChange} />
                    </div>
                    <div className="col-md-3">
                        <label className='label-title'>Contacto</label>
                        <br />
                        <div class="input-container">
                            <input className='loginInput' value={contacto} name='contacto' onChange={handleSubclienteChange} />
                            <i class="icon fa-solid fa-phone" />
                        </div>
                    </div>

                    <div className="col-md-12 mt-2">
                        <button onClick={handleSubclienteCreate} className='btn-blue'> <i class="fa-regular fa-floppy-disk"></i> Guardar</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SubclienteCreate