import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// Componentes
import OperatorNav from "../../components/navs/OperatorNav";
import MovimientoCreate from "./MovimientoCreate";
import Loading from "../../components/others/loading";

// Funciones
import { getAlmacenes } from "../../functions/api/almacenes";
import { getClientes } from "../../functions/api/clientes";
import { getEstados } from "../../functions/api/estados";
import { getTipoContenedor } from "../../functions/api/tipoContenedor";
import { getChoferes } from "../../functions/api/choferes";
import { getFlota } from "../../functions/api/flota";
import { createMovimiento } from "../../functions/api/movimientos";
import { getSubClientes } from "../../functions/api/subclientes";
import { formatNroContainer } from "../../functions/utilities/format";

const initialState = {
  numero_contenedor: "",
  numero_sello: "",
  numero_guia: "",
  activo: true,
  user_id: null,
  chofer_id_entrada: null,
  cliente_id: null,
  subcliente_id: null,
  tipo_contenedor_id: 0,
  estado: "STOCK",
  almacen_id: 1,
  camion_id: null,
  refeer: false,
  conexion: "",
  carga_peligrosa: "",
  fecha_ingreso: "",
  fecha_recepcion: "",
  hora_recepcion: "",
};

const IngresarMovimiento = () => {
  const [isSelected, setIsSelected] = useState(false);
  const [values, setValues] = useState(initialState);
  const [clientes, setClientes] = useState([]);
  const [almacenes, setAlmacenes] = useState([]);
  const [choferes, setChoferes] = useState([]);
  const [subclientes, setSubClientes] = useState([]);
  const [flota, setFlota] = useState([]);
  const [tipoContenedor, setTipoContenedor] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);

  let { user } = useSelector((state) => ({ ...state }));

  let navigate = useNavigate();

  useEffect(() => {
    loadAlmacenes();
    loadChoferes();
    loadSubClientes();
    loadClientes();
    loadTipoContenedor();
    loadFlota();
  }, []);

  const loadAlmacenes = () => {
    setLoading(true);
    getAlmacenes(user.token, page, perPage)
      .then((res) => {
        setAlmacenes(res.data.filter((d) => d.activo === true));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const loadChoferes = () => {
    setLoading(true);
    getChoferes(user.token, page, perPage)
      .then((res) => {
        setChoferes(res.data.filter((d) => d.activo === true));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const loadSubClientes = () => {
    setLoading(true);
    getSubClientes(user.token, page, perPage)
      .then((res) => {
        setSubClientes(res.data.filter((d) => d.activo === true));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const loadClientes = () => {
    setLoading(true);
    getClientes(user.token, page, perPage)
      .then((res) => {
        setClientes(res.data.filter((d) => d.activo === true));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const loadTipoContenedor = () => {
    setLoading(true);
    getTipoContenedor(user.token, page, perPage)
      .then((res) => {
        setTipoContenedor(res.data.filter((d) => d.activo === true));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const loadFlota = () => {
    setLoading(true);
    getFlota(user.token, page, perPage)
      .then((res) => {
        setFlota(res.data.filter((d) => d.activo === true));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  // id movimiento
  const id = useRef();

  const handleChange = (e) => {
    if (e.target.name === "camion_id_entrada") {
      console.log(e.target.value);
      console.log(flota);
      let camion = flota.filter((d) => d.id === Number(e.target.value));
      console.log(camion[0].chofer_id);
      values.chofer_id_entrada = camion[0].chofer_id;
      setValues({ ...values, [e.target.name]: e.target.value });
    } else if (e.target.name === "numero_contenedor") {
      setValues({
        ...values,
        [e.target.name]: formatNroContainer(e.target.value),
      });
    } else {
      setValues({ ...values, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(values.fecha_ingreso);

    let currentDate;

    if (!values.fecha_ingreso) {
      currentDate = new Date();
    } else {
      currentDate = new Date(values.fecha_ingreso);
    }

    var year = currentDate.getFullYear();
    var month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 to month since it's zero-based
    var day = currentDate.getDate().toString().padStart(2, "0");

    // Get the current hour
    var currentHour = currentDate.getHours().toString().padStart(2, "0");
    var currentMinutes = currentDate.getMinutes().toString().padStart(2, "0");

    var formattedDate = year + "-" + month + "-" + day;

    values.fecha_recepcion = formattedDate;
    values.hora_recepcion = currentHour + ":" + currentMinutes + ":00";

    if (values.numero_contenedor === "") {
      toast.error("Ingrese Nº de contenedor");
    } else if (values.cliente_id === 0) {
      toast.error("Ingrese Cliente");
    } else if (values.tipo_contenedor_id === 0) {
      toast.error("Ingrese Tipo de contenedor");
    } else {
      values.user_id = user.id;
      values.refeer = isSelected;

      setLoading(true);
      createMovimiento(user.token, values)
        .then((res) => {
          toast.success("Movimiento creado exitosamente");
          setLoading(false);
          navigate("/operador/registro-movimientos");
        })
        .catch((err) => {
          console.log(err);

          setLoading(false);
        });
    }
  };

  return loading ? (
    <Loading />
  ) : (
    <div>
      <OperatorNav />
      <div className=" m-5">
        <MovimientoCreate
          values={values}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          almacenes={almacenes}
          loadChoferes={loadChoferes}
          loadFlota={loadFlota}
          subclientes={subclientes}
          choferes={choferes}
          clientes={clientes}
          tipoContenedor={tipoContenedor}
          flota={flota}
          isSelected={isSelected}
          setIsSelected={setIsSelected}
        />
      </div>
    </div>
  );
};

export default IngresarMovimiento;
