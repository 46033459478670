import axios from "axios";




export const getChoferes = async(token,page,per_page) =>{
    return await axios.get(`${process.env.REACT_APP_API}/users/choferes?page=${page}&per_page=${per_page}`,{
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
}




export const createChofer = async(token,body) =>{
    return await axios.post(`${process.env.REACT_APP_API}/users/create_chofer`,body,{
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
}


export const updateChofer = async(token,id,body) =>{
    return await axios.put(`${process.env.REACT_APP_API}/users/update_chofer/${id}`,body,{
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
}