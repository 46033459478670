import React, { useState } from 'react'
import {
    Card,
    CardHeader,
    CardBody,
    Button,
    Chip,
    CardFooter,
    Divider,
    Image,
    Input,
    Select,
    Switch,
    SelectItem
} from "@nextui-org/react";
import ModalCamion from './ModalCamion';
import ModalConductor from './ModalConductor'


const MovimientoCreate = ({ handleSubmit, handleChange, values, almacenes, choferes, clientes, subclientes, tipoContenedor, flota, isSelected, setIsSelected, loadChoferes, loadFlota }) => {

    const { fecha_ingreso, numero_contenedor, numero_sello, numero_guia, refeer, conexion, carga_peligrosa, chofer_id_entrada, cliente_id, tipo_contenedor_id, subcliente_id, almacen_id, camion_id_entrada } = values

    const currentDate = new Date();


    const subclientes_filter = subclientes && subclientes.filter(d => d.cliente_id === Number(cliente_id))


    const [modalCamion, setModalCamion] = useState(false)
    const [modalConductor, setModalConductor] = useState(false)

    const openModalCamion = () => {
        setModalCamion(!modalCamion)
    }

    const openModalConductor = () => {
        setModalConductor(!modalConductor)
    }

    return (

        <>
            <ModalCamion isOpen={modalCamion} onOpenChange={openModalCamion} setOpen={setModalCamion} loadChoferes={loadChoferes} loadFlota={loadFlota} />
            <ModalConductor isOpen={modalConductor} onOpenChange={openModalConductor} setOpen={setModalConductor} loadChoferes={loadChoferes} loadFlota={loadFlota} />

            <Card className="w-full">
                <CardHeader>
                    <div className='flex gap-3 '>
                        <Image
                            alt="nextui logo"
                            height={40}
                            radius="sm"
                            src="https://avatars.githubusercontent.com/u/86160567?s=200&v=4"
                            width={40}
                        />
                        <div className="flex flex-col">
                            <p className="text-md">FORMULARIO INGRESO DE MOVIMIENTOS</p>
                            <Chip className='bg-indigo-500 text-white'>Ingreso  {currentDate.toLocaleDateString()}</Chip>
                        </div>
                    </div>
                </CardHeader>
                <Divider />
                <CardBody>
                    <div className='grid lg:grid-cols-3 md:grid-cols-2 gap-10'>
                        <div className="flex flex-col">
                            <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                                <Input
                                    type="datetime-local"
                                    label="Fecha Recepción"
                                    labelPlacement='outside'
                                    placeholder="Fecha recepción"
                                    value={fecha_ingreso}
                                    variant='bordered'
                                    // className='uppercase'
                                    name='fecha_ingreso'
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                       

                        <div className="flex flex-col">
                            <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                                <Input
                                    type="text"
                                    label="Nª de contenedor"
                                    labelPlacement='outside'
                                    placeholder="Ingrese nro contenedor"
                                    value={numero_contenedor}
                                    variant='bordered'
                                    // className='uppercase'
                                    name='numero_contenedor'
                                    onChange={handleChange}
                                />
                            </div>

                        </div>

                        <div className="flex flex-col">
                            <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                                <Input
                                    type="text"
                                    label="Nª de sello"
                                    labelPlacement='outside'
                                    placeholder="Ingrese nro sello"
                                    value={numero_sello}
                                    variant='bordered'
                                    name='numero_sello'
                                    onChange={handleChange}
                                />
                            </div>

                        </div>

                        <div className="flex flex-col">
                            <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                                <Input
                                    type="text"
                                    label="Nª de guía"
                                    labelPlacement='outside'
                                    placeholder="Ingrese nro guía"
                                    value={numero_guia}
                                    variant='bordered'
                                    name='numero_guia'
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="flex flex-col">
                            <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                                <Select
                                    items={clientes}
                                    label="Cliente"
                                    labelPlacement='outside'
                                    placeholder="Seleccionar Cliente"
                                    variant='bordered'
                                    value={cliente_id}
                                    name='cliente_id'
                                    onChange={handleChange}
                                >
                                    {(clientes) => <SelectItem key={clientes.id}>{clientes.razon_social}</SelectItem>}
                                </Select>
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                                <Select
                                    items={subclientes_filter}
                                    label="Subcliente"
                                    labelPlacement='outside'
                                    placeholder="Seleccionar Subcliente"
                                    variant='bordered'
                                    value={subcliente_id}
                                    name='subcliente_id'
                                    onChange={handleChange}
                                >
                                    {(items) => <SelectItem key={items.id}>{items.razon_social}</SelectItem>}
                                </Select>
                            </div>
                        </div>
                        {/* <div className="flex flex-col">
                        <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                            <Select
                                items={almacenes}
                                label="Almacén"
                                labelPlacement='outside'
                                placeholder="Seleccionar Almacén"
                                variant='bordered'
                                value={almacen_id}
                                name='almacen_id'
                                onChange={handleChange}
                            >
                                {(items) => <SelectItem key={items.id}>{items.nombre}</SelectItem>}
                            </Select>
                        </div>
                    </div> */}
                        <div className="flex flex-col">
                            <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">

                                <Select
                                    items={flota}
                                    label="Camión"
                                    labelPlacement='outside'
                                    placeholder="Seleccionar Camión"
                                    variant='bordered'
                                    value={camion_id_entrada}
                                    name='camion_id_entrada'
                                    onChange={handleChange}
                                >
                                    {(flota) => <SelectItem key={flota.id}>{flota.placa_patente}</SelectItem>}
                                </Select>
                                <Button onClick={() => openModalCamion()} className='bg-foreground text-white justify-start w-full' size='sm'><span className='font-bold text-xl'>+</span> Agregar Camión</Button>
                            </div>
                        </div>

                        <div className="flex flex-col">
                            <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-1 inline-block">
                                <label className='font-semibold text-sm'>Conductor</label>
                                <div className='w-full flex flex-col'>
                                    <div className='relative w-full inline-flex tap-highlight-transparent flex-row items-center shadow-sm px-3  border-medium border-default-200 data-[hover=true]:border-default-400 group-data-[focus=true]:border-foreground h-unit-10 min-h-unit-10 rounded-medium transition-background !duration-150 transition-colors motion-reduce:transition-none'>
                                        <select
                                            value={chofer_id_entrada}
                                            name='chofer_id_entrada'
                                            onChange={handleChange}
                                            className='w-full'
                                        >
                                            <option>Seleccionar Conductor</option>
                                            {choferes && choferes.map(c => (
                                                <option value={c.id}>{c.nombre_completo}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <Button onClick={() => openModalConductor()} className='bg-foreground text-white justify-start w-full mt-3' size='sm'><span className='font-bold text-xl'>+</span> Agregar Conductor</Button>
                                </div>
                            </div>
                        </div>




                        <div className="flex flex-col">
                            <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                                <Select
                                    items={tipoContenedor}
                                    label="Tipo de Contenedor"
                                    labelPlacement='outside'
                                    placeholder="Seleccionar Tipo de Contenedor"
                                    variant='bordered'
                                    value={tipo_contenedor_id}
                                    name='tipo_contenedor_id'
                                    onChange={handleChange}
                                >
                                    {(tipoContenedor) => <SelectItem key={tipoContenedor.id}>{tipoContenedor.dimension}</SelectItem>}
                                </Select>
                            </div>
                        </div>

                        <div className="flex flex-col">
                            <div className="flex w-full mt-10 flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                                <Switch
                                    value={refeer}
                                    name='refeer'
                                    isSelected={isSelected}
                                    onValueChange={setIsSelected}
                                >
                                    Refeer
                                </Switch>

                            </div>
                        </div>

                        <div className="flex flex-col">
                            <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                                <Select
                                    label="Conexión"
                                    labelPlacement='outside'
                                    placeholder="Seleccionar Conexión"
                                    variant='bordered'
                                    value={conexion}
                                    name='conexion'
                                    onChange={handleChange}
                                >
                                    <SelectItem key='conectado'>Conectado</SelectItem>
                                    <SelectItem key='desconectado'>Desconectado</SelectItem>
                                </Select>
                            </div>
                        </div>

                        <div className="flex flex-col">
                            <div className="flex w-full flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
                                <Select
                                    label="Carga Peligrosa"
                                    labelPlacement='outside'
                                    placeholder="Seleccionar si es Carga Peligrosa o No"
                                    variant='bordered'
                                    value={carga_peligrosa}
                                    name='carga_peligrosa'
                                    onChange={handleChange}
                                >
                                    <SelectItem key='si'>Si</SelectItem>
                                    <SelectItem key='no'>No</SelectItem>
                                </Select>
                            </div>
                        </div>



                    </div>
                </CardBody>
                <Divider />
                <CardFooter>
                    <Button className='w-full text-white transition-all bg-slate-600 hover:bg-slate-300 hover:text-slate-700 ' onClick={handleSubmit}>
                        Guardar
                    </Button>
                </CardFooter>
            </Card>

        </>
    )
}

export default MovimientoCreate