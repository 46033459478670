import React from 'react'
import { formatRut } from '../../../functions/utilities/format'

const SubClientesList = ({ handleModalSubcliente, handleUpdateSubCliente, handleDeleteSubCliente, handleEditSubClientesUpdate, valuesSubClientesUpdate }) => {

    // const { razon_social, rut, direccion, contacto, email } = valuesUpdate



    return (
        <>
            <div className='modalBackground' onClick={handleModalSubcliente}>
            </div>
            <div className='modalCard'>
                <div className="row">
                    <div className="col-md-12">
                        <p className='modalTitle'>SUBCLIENTES</p>
                    </div>
                    <div className="col-md-12 mb-4">
                        <hr className='bg-blue' />
                    </div>
                    {valuesSubClientesUpdate && valuesSubClientesUpdate
                    .filter( d => d.activo === true)
                    .map((c, index) => (
                        <>

                            <div className="col-md-2 ">
                                <label className='label-title'>Rut</label>
                                <br />
                                <input className='loginInput' value={formatRut(c.rut)} name='rut' maxLength='12' onChange={handleEditSubClientesUpdate(index)} />
                            </div>

                            <div className="col-md-2">
                                <label className='label-title'>Razón Social</label>
                                <br />
                                <input className='loginInput' value={c.razon_social} name='razon_social' onChange={handleEditSubClientesUpdate(index)} />
                            </div>
                            <div className="col-md-2 ">
                                <label className='label-title'>Email</label>
                                <br />
                                <div class="input-container">
                                    <input className='loginInput' value={c.email} name='email' onChange={handleEditSubClientesUpdate(index)} />
                                    <i class="fa-solid fa-envelope icon" />
                                </div>
                            </div>

                            <div className="col-md-3">
                                <label className='label-title'>Dirección</label>
                                <br />
                                <input className='loginInput' value={c.direccion} name='direccion' onChange={handleEditSubClientesUpdate(index)} />
                            </div>
                            <div className="col-md-2">
                                <label className='label-title'>Contacto</label>
                                <br />
                                <div class="input-container">
                                    <input className='loginInput' value={c.contacto} name='contacto' onChange={handleEditSubClientesUpdate(index)} />
                                    <i class="icon fa-solid fa-phone" />
                                </div>
                            </div>
                            <div className='col-md-1 mt-4'>
                                <button className='btn btn-danger btn-sm' onClick={handleDeleteSubCliente(index)}>Eliminar</button>
                            </div>
                            <div className='col-md-12 mb-3'></div>
                        </>
                    ))}

                    <div className='col-md-12 mt-5'>
                        <button onClick={handleUpdateSubCliente} className='btn-blue'> <i class="fa-regular fa-floppy-disk"></i> Actualizar</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SubClientesList