import React from 'react'


import DataTable from 'react-data-table-component'



const TipoContenedorTable = ({values,handleModal}) => {


    const columns = [
        {
          name: 'ID',
          selector: row => row.id,
          width:'60px'
        },
        {
            name: 'Dimensión',
            selector: row => row.dimension
        },
        {
            name: 'Descripción',
            selector: row => row.desc
        },
        {
            name: 'Detalle',
            selector: row => row.detalle
        }
    ]


    const data = values && values.map( c => {
        return {
            id:c.id,
            dimension:c.dimension,
            desc:c.descripcion,
            refeer:c.refeer && c.refeer === true ? <button className='btn btn-success btn-sm w-100'>Activo</button>:<button className='btn btn-secondary btn-sm w-100'>Inactivo</button>,
            conexion:c.conexion,
            carga_peligrosa: c.carga_peligrosa,
            detalle: <button onClick={handleModal} value={c.id} className='btn btn-primary'> <i class="fa-solid fa-magnifying-glass"></i> Detalle</button>
            
        }
    })

  return (
    <DataTable
    data={data}
    columns={columns}
    pagination
    />
  )
}

export default TipoContenedorTable