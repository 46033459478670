import axios from 'axios'

export const getTipoContenedor = async(token,page,per_page) =>{
    return await axios.get(`${process.env.REACT_APP_API}/users/tipo_contenedores?page=${page}&per_page=${per_page}`,{
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
}


export const createTipoContenedor = async(token,body) =>{
    return await axios.post(`${process.env.REACT_APP_API}/users/create_tipo_contenedor`,body,{
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
}


export const updateTipoContenedor = async(token,id,body) =>{
    return await axios.put(`${process.env.REACT_APP_API}/users/update_tipocontenedor/${id}`,body,{
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
}