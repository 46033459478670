import React, {
  useCallback,
  useMemo,
  useState,
  useRef,
  useEffect,
} from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  Spinner,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  TableCell,
  Pagination,
  Chip,
  Input,
  Switch,
  Select,
  SelectItem,
} from "@nextui-org/react";

import Loading from "../others/loading";
import { toast } from "react-toastify";

import { DeleteIcon } from "../../img/DeleteIcon";
import { EditIcon } from "../../img/EditIcon";
import { EyeIcon } from "../../img/EyeIcon";
import {
  getMovimientoById,
  updateAprobacion,
  getMovimientosUpdateRequest,
} from "../../functions/api/movimientos";

import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";

const filtersInitialState = {
  numero_contenedor: "",
  numero_guia: "",
  fecha_recepcion_desde: "",
  fecha_recepcion_hasta: "",
};

const MovimientosRequestsTable = ({ loading, token, user, setLoading }) => {
  const [openEye, setOpenEye] = useState(false);
  const [movById, setMovById] = useState([]);
  const [values, setValues] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [filterValue, setFilterValue] = useState(filtersInitialState);

  useEffect(() => {
    loadMovimientos();
  }, []);

  const loadMovimientos = () => {
    setLoading(true);
    getMovimientosUpdateRequest(user.token, page, perPage)
      .then((res) => {
        setValues(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const columns = [
    {
      label: "ID",
      key: "id",
    },
    {
      label: "Nª Contenedor",
      key: "new_numero_contenedor",
    },
    {
      label: "Nª Guía",
      key: "new_numero_guia",
    },
    {
      label: "Estado Aprobación",
      key: "approval_status",
    },
    {
      label: "Acciones",
      key: "actions",
    },
  ];

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const filteredItems = useMemo(() => {
    let filterValues = [...values];

    if (filterValue.numero_contenedor !== "") {
      filterValues = filterValues.filter((c) =>
        c.new_numero_contenedor
          .toUpperCase()
          .includes(filterValue.numero_contenedor.toUpperCase())
      );
    }

    if (filterValue.numero_guia !== "") {
      filterValues = filterValues.filter((c) =>
        c.new_numero_guia
          .toUpperCase()
          .includes(filterValue.numero_guia.toUpperCase())
      );
    }

    // filtrar desde hasta fecha
    if (
      filterValue.fecha_recepcion_desde !== "" &&
      filterValue.fecha_recepcion_hasta !== ""
    ) {
      filterValues = filterValues.filter((item) => {
        const itemDate = new Date(item.new_fecha_recepcion);
        const startDate = new Date(filterValue.fecha_recepcion_desde);
        const endDate = new Date(filterValue.fecha_recepcion_hasta);
        return itemDate >= startDate && itemDate <= endDate;
      });
    } else if (
      filterValue.fecha_recepcion_desde !== "" &&
      filterValue.fecha_recepcion_hasta === ""
    ) {
      filterValues = filterValues.filter((item) => {
        const itemDate = new Date(item.new_fecha_recepcion);
        const startDate = new Date(filterValue.fecha_recepcion_desde);
        return itemDate >= startDate;
      });
    } else if (
      filterValue.fecha_recepcion_desde === "" &&
      filterValue.fecha_recepcion_hasta !== ""
    ) {
      filterValues = filterValues.filter((item) => {
        const itemDate = new Date(item.new_fecha_recepcion);
        const startDate = new Date(filterValue.fecha_recepcion_desde);
        const endDate = new Date(filterValue.fecha_recepcion_hasta);
        return itemDate <= endDate;
      });
    }

    return filterValues;
  }, [values, filterValue]);

  const items = useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;

    return filteredItems.slice(start, end);
  }, [page, filteredItems]);

  const onRowsPerPageChange = useCallback((e) => {
    setRowsPerPage(Number(e.target.value));
    setPage(1);
  }, []);

  const detailMov = useRef([]);

  const onOpenEye = (value) => {
    console.log(value);
    if (openEye === true) {
      setOpenEye(!openEye);
    } else {
      getMovimientoById(token, Number(value.movimiento_id))
        .then((res) => {
          console.log(res.data.data[0]);
          setMovById(res.data.data[0]);
          setOpenEye(!openEye);
          detailMov.current = value;
        })
        .catch((err) => {
          console.log(err);
        });
    }
    console.log(detailMov);
  };

  const tipoMovColorMap = {
    approved: "success",
    pending: "warning",
    rejected: "danger",
  };

  const renderCell = useCallback((value, columnKey) => {
    const cellValue = value[columnKey];

    switch (columnKey) {
      case "approval_status":
        return (
          <Chip
            className="capitalize "
            color={tipoMovColorMap[value.approval_status]}
            size="sm"
            variant="flat"
          >
            {cellValue}
          </Chip>
        );
      case "actions":
        return (
          <div className="relative flex items-center gap-2">
            <Button
              isIconOnly
              onClick={() => onOpenEye(value)}
              className="text-lg w-1 h-5 bg-transparent text-slate-500 cursor-pointer active:opacity-50"
              aria-label="Like"
            >
              <EyeIcon />
            </Button>
          </div>
        );
      default:
        return cellValue;
    }
  }, []);

  const handleChangeFilter = (e) => {
    console.log(e.target.value);
    setFilterValue({ ...filterValue, [e.target.name]: e.target.value });
  };

  const onClear1 = () => {
    setFilterValue({ ...filterValue, numero_contenedor: "" });
  };

  const onClear2 = () => {
    setFilterValue({ ...filterValue, numero_guia: "" });
  };

  const onClear3 = () => {
    setFilterValue({ ...filterValue, fecha_recepcion_desde: "" });
  };

  const onClear4 = () => {
    setFilterValue({ ...filterValue, fecha_recepcion_hasta: "" });
  };

  const topContent = useMemo(() => {
    return (
      <div className="grid gap-4 grid-cols-4">
        {/* {JSON.stringify(filterValue)} */}
        <Input
          isClearable
          placeholder="Buscar contenedor"
          size="sm"
          startContent={<MagnifyingGlassIcon className="h-6" />}
          name="numero_contenedor"
          value={filterValue.numero_contenedor}
          onClear={() => onClear1()}
          onChange={handleChangeFilter}
        />

        <Input
          isClearable
          placeholder="Buscar Nº guia"
          size="sm"
          startContent={<MagnifyingGlassIcon className="h-6" />}
          name="numero_guia"
          value={filterValue.numero_guia}
          onClear={() => onClear2()}
          onChange={handleChangeFilter}
        />

        <Input
          isClearable
          type="date"
          size="sm"
          label="Fecha Desde"
          labelPlacement="outside-left"
          name="fecha_recepcion_desde"
          value={filterValue.fecha_recepcion_desde}
          onClear={() => onClear3()}
          onChange={handleChangeFilter}
        />

        <Input
          isClearable
          type="date"
          size="sm"
          label="Fecha Hasta"
          labelPlacement="outside-left"
          name="fecha_recepcion_hasta"
          value={filterValue.fecha_recepcion_hasta}
          onClear={() => onClear4()}
          onChange={handleChangeFilter}
        />
      </div>
    );
  }, [filterValue, handleChangeFilter, onClear1, onClear2, onClear3, onClear4]);

  const pages = Math.ceil(filteredItems.length / rowsPerPage);

  const handleAprobar = () => {
    //
    updateAprobacion(token, detailMov.current.id, {
      approval_action: "approved",
    })
      .then((res) => {
        toast.success("Solcitud Aprobada Exitosamente!");
        loadMovimientos();
        setOpenEye(!openEye);
      })
      .catch((err) => {
        console.log(err);
        setOpenEye(!openEye);
      });
  };

  const handleRechazar = () => {
    //
    updateAprobacion(token, detailMov.current.id, {
      approval_action: "rejected",
    })
      .then((res) => {
        toast.success("Solcitud Rechazada Exitosamente!");
        loadMovimientos();
        setOpenEye(!openEye);
      })
      .catch((err) => {
        console.log(err);
        setOpenEye(!openEye);
      });
  };

  return pages ? (
    <>
      <Modal
        scrollBehavior="inside"
        size="5xl"
        isOpen={openEye}
        onOpenChange={onOpenEye}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                <div className="flex flex-col">
                  <p className="text-md">
                    Movimiento Nº {detailMov.current.movimiento_id}{" "}
                  </p>
                </div>
              </ModalHeader>
              <ModalBody>
                <div className="relative flex flex-col shadow-lg  w-full">
                  <table className="table-auto  ">
                    <thead>
                      <tr className="bg-sky-500 text-white">
                        <th className="text-sm px-6 py-2">CAMPOS</th>
                        <th>ANTES</th>
                        <th>MODIFICACIÓN</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        className={
                          movById.fecha_recepcion !==
                            detailMov.current.new_fecha_recepcion &&
                          "bg-green-500 text-white"
                        }
                      >
                        <th className="text-sm px-6 py-1">Fecha Recepción</th>
                        <td className="uppercase">{movById.fecha_recepcion}</td>
                        <td className="uppercase">
                          {detailMov.current.new_fecha_recepcion}
                        </td>
                      </tr>
                      <tr
                        className={
                          movById.hora_recepcion !==
                            detailMov.current.new_hora_recepcion &&
                          "bg-green-500 text-white"
                        }
                      >
                        <th className="text-sm px-6 py-1">Hora Recepción</th>
                        <td className="uppercase">{movById.hora_recepcion}</td>
                        <td className="uppercase">
                          {detailMov.current.new_hora_recepcion}
                        </td>
                      </tr>
                      <tr
                        className={
                          movById.fecha_salida !==
                            detailMov.current.new_fecha_salida &&
                          "bg-green-500 text-white"
                        }
                      >
                        <th className="text-sm px-6 py-1">Fecha Salida</th>
                        <td className="uppercase">{movById.fecha_salida}</td>
                        <td className="uppercase">
                          {detailMov.current.new_fecha_salida}
                        </td>
                      </tr>
                      <tr
                        className={
                          movById.hora_salida !==
                            detailMov.current.new_hora_salida &&
                          "bg-green-500 text-white"
                        }
                      >
                        <th className="text-sm px-6 py-1">Hora Salida</th>
                        <td className="uppercase">{movById.hora_salida}</td>
                        <td className="uppercase">
                          {detailMov.current.new_hora_salida}
                        </td>
                      </tr>

                      <tr
                        className={
                          movById.numero_contenedor !==
                            detailMov.current.new_numero_contenedor &&
                          "bg-green-500 text-white"
                        }
                      >
                        <th className="text-sm px-6 py-1">Nª Contenedor</th>
                        <td className="uppercase">
                          {movById.numero_contenedor}
                        </td>
                        <td className="uppercase">
                          {detailMov.current.new_numero_contenedor}
                        </td>
                      </tr>
                      <tr
                        className={
                          movById.numero_guia !==
                            detailMov.current.new_numero_guia &&
                          "bg-green-500 text-white"
                        }
                      >
                        <th className="text-sm px-6 py-1">Nª Guía</th>
                        <td className="uppercase">{movById.numero_guia}</td>
                        <td className="uppercase">
                          {detailMov.current.new_numero_guia}
                        </td>
                      </tr>
                      <tr
                        className={
                          movById.numero_sello !==
                            detailMov.current.new_numero_sello &&
                          "bg-green-500 text-white"
                        }
                      >
                        <th className="text-sm px-6 py-1">Nº Sello</th>
                        <td className="uppercase">{movById.numero_sello}</td>
                        <td className="uppercase">
                          {detailMov.current.new_numero_sello}
                        </td>
                      </tr>
                      <tr
                        className={
                          movById.nombre_conductor_entrada !==
                            detailMov.current.nombre_conductor_entrada &&
                          "bg-green-500 text-white"
                        }
                      >
                        <th className="text-sm px-6 py-1">Chofer Ingreso</th>
                        <td>{movById.nombre_conductor_entrada}</td>
                        <td>{detailMov.current.nombre_conductor_entrada}</td>
                      </tr>
                      <tr
                        className={
                          movById.placa_patente_entrada !==
                            detailMov.current.placa_patente_entrada &&
                          "bg-green-500 text-white"
                        }
                      >
                        <th className="text-sm px-6 py-1">Camión Ingreso</th>
                        <td>{movById.placa_patente_entrada}</td>
                        <td>{detailMov.current.placa_patente_entrada}</td>
                      </tr>
                      {detailMov.current.new_estado === "DESPACHADO" && (
                        <>
                          <tr
                            className={
                              movById.nombre_conductor_salida !==
                                detailMov.current.nombre_conductor_salida &&
                              "bg-green-500 text-white"
                            }
                          >
                            <th className="text-sm px-6 py-1">Chofer Salida</th>
                            <td>{movById.nombre_conductor_salida}</td>
                            <td>{detailMov.current.nombre_conductor_salida}</td>
                          </tr>
                          <tr
                            className={
                              movById.placa_patente_salida !==
                                detailMov.current.placa_patente_salida &&
                              "bg-green-500 text-white"
                            }
                          >
                            <th className="text-sm px-6 py-1">Camión Salida</th>
                            <td>{movById.placa_patente_salida}</td>
                            <td>{detailMov.current.placa_patente_salida}</td>
                          </tr>
                        </>
                      )}
                      <tr
                        className={
                          movById.razon_social_cliente !==
                            detailMov.current.razon_social_cliente &&
                          "bg-green-500 text-white"
                        }
                      >
                        <th className="text-sm px-6 py-1">Cliente</th>
                        <td>{movById.razon_social_cliente}</td>
                        <td>{detailMov.current.razon_social_cliente}</td>
                      </tr>
                      <tr
                        className={
                          movById.razon_social_subcliente !==
                            detailMov.current.razon_social_subcliente &&
                          "bg-green-500 text-white"
                        }
                      >
                        <th className="text-sm px-6 py-1">Subcliente</th>
                        <td>{movById.razon_social_subcliente}</td>
                        <td>{detailMov.current.razon_social_subcliente}</td>
                      </tr>
                      <tr
                        className={
                          movById.nombre !== detailMov.current.almacen &&
                          "bg-green-500 text-white"
                        }
                      >
                        <th className="text-sm px-6 py-1">Almacén</th>
                        <td>{movById.nombre}</td>
                        <td>{detailMov.current.almacen}</td>
                      </tr>
                      <tr
                        className={
                          movById.descripcion_tipo_contenedor !==
                            detailMov.current.descripcion_tipo_contenedor &&
                          "bg-green-500 text-white"
                        }
                      >
                        <th className="text-sm px-6 py-1">
                          Tipo de contenedor
                        </th>
                        <td>{movById.descripcion_tipo_contenedor}</td>
                        <td>{detailMov.current.descripcion_tipo_contenedor}</td>
                      </tr>
                      <tr
                        className={
                          movById.refeer !== detailMov.current.new_refeer &&
                          "bg-green-500 text-white"
                        }
                      >
                        <th className="text-sm px-6 py-1">Refeer</th>
                        <td>
                          {movById.refeer ? <>Conectado</> : <>Desconectado</>}
                        </td>
                        <td>
                          {detailMov.current.new_refeer ? (
                            <>Conectado</>
                          ) : (
                            <>Desconectado</>
                          )}
                        </td>
                      </tr>
                      <tr
                        className={
                          movById.conexion !== detailMov.current.new_conexion &&
                          "bg-green-500 text-white"
                        }
                      >
                        <th className="text-sm px-6 py-1">Conexión</th>
                        <td className="capitalize">{movById.conexion}</td>
                        <td className="capitalize">
                          {detailMov.current.new_conexion}
                        </td>
                      </tr>
                      <tr
                        className={
                          movById.carga_peligrosa !==
                            detailMov.current.new_carga_peligrosa &&
                          "bg-green-500 text-white"
                        }
                      >
                        <th className="text-sm px-6 py-1">Carga Peligrosa</th>
                        <td className="capitalize">
                          {movById.carga_peligrosa}
                        </td>
                        <td className="capitalize">
                          {detailMov.current.new_carga_peligrosa}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </ModalBody>
              <ModalFooter>
                {detailMov.current.approval_status === "pending" && (
                  <>
                    <Button
                      className="w-full"
                      color="danger"
                      onPress={handleRechazar}
                    >
                      Rechazar
                    </Button>
                    <Button
                      className="w-full text-white"
                      color="success"
                      onPress={handleAprobar}
                    >
                      Aprobar
                    </Button>
                  </>
                )}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <Table
        topContent={topContent}
        aria-label="Example table with dynamic content"
        bottomContent={
          <div className="flex w-full justify-center">
            <Pagination
              isCompact
              showControls
              showShadow
              color="secondary"
              page={page}
              total={pages}
              onChange={(page) => setPage(page)}
            />
          </div>
        }
      >
        <TableHeader columns={columns}>
          {(column) => (
            <TableColumn key={column.key}>{column.label}</TableColumn>
          )}
        </TableHeader>
        <TableBody
          isLoading={loading}
          loadingContent={<Spinner label="Loading..." />}
          items={items}
        >
          {(item) => (
            <TableRow key={item.key}>
              {(columnKey) => (
                <TableCell>{renderCell(item, columnKey)}</TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  ) : (
    <Table
      topContent={topContent}
      aria-label="Example table with dynamic content"
      emptyContent={"No hay datos para mostrar."}
      bottomContent={
        <div className="flex w-full justify-center">
          <Pagination
            isCompact
            showControls
            showShadow
            color="secondary"
            page={page}
            total={pages}
            onChange={(page) => setPage(page)}
          />
        </div>
      }
    >
      <TableHeader columns={columns}>
        {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
      </TableHeader>
      <TableBody
        isLoading={loading}
        loadingContent={<Spinner label="Loading..." />}
        items={items}
      >
        {(item) => (
          <TableRow key={item.key}>
            {(columnKey) => (
              <TableCell>{renderCell(item, columnKey)}</TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default MovimientosRequestsTable;
