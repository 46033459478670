import React from 'react'

const AlmacenCreate = ({handleSubmit,handleChange,values}) => {



    const { codigo,nombre,direccion } = values


  return (
    <>
        <div className="row">
            <div className="col-md-12">
                <p className='modalTitle'>CREAR ALMACEN</p>
            </div>
            <div className="col-md-2 ">
                <label className='label-title'>Código</label>
                <br/>
                <input className='loginInput' value={codigo} name='codigo' onChange={handleChange} />
                
            </div>
            <div className="col-md-5 ">
                <label className='label-title'>Nombre</label>
                <br/>
                <input className='loginInput' value={nombre} name='nombre' onChange={handleChange} />
                
            </div>
            <div className="col-md-5 ">
                <label className='label-title'>Dirección</label>
                <br/>
                <input className='loginInput' value={direccion} name='direccion' onChange={handleChange} />
                
            </div>
            
            <div className='col-md-12 mt-3'>
                <button onClick={handleSubmit} className='btn-blue'> <i class="fa-regular fa-floppy-disk"></i> Guardar</button>
            </div>
        </div>
</>
  )
}

export default AlmacenCreate