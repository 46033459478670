import axios from "axios";

export const createMovimiento = async (token, data) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/users/movimientos`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getMovimientos = async (token, page, per_page) => {
  return await axios.get(
    `${process.env.REACT_APP_API}/users/get_movimientos?page=${page}&per_page=${per_page}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getMovimientosAll = async (token, page, per_page) => {
  return await axios.get(
    `${process.env.REACT_APP_API}/users/get_all_movimientos_requests?page=${page}&per_page=${per_page}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getMovimientoById = async (token, id) => {
  return await axios.get(
    `${process.env.REACT_APP_API}/users/movimientos_by_id/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const updateMovimientos = async (token, data, id) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/users/solicitar-modificacion/${id}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const updateMovimientoSalida = async (token, data, id) => {
  return await axios.put(
    `${process.env.REACT_APP_API}/users/movimientos/salida/${id}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getMovimientosUpdateRequest = async (token, page, per_page) => {
  return await axios.get(
    `${process.env.REACT_APP_API}/users/get_all_movimientos_requests?page=${page}&per_page=${per_page}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const updateAprobacion = async (token, id, body) => {
  return await axios.put(
    `${process.env.REACT_APP_API}/users/movimientos/approve/${id}`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

// export const getReport = async(token) =>{
//     return await axios.get(`${process.env.REACT_APP_API}/download_report`,{
//         headers:{
//             Authorization:`Bearer ${token}`
//         }
//     })
// }

export const getReport = async (token) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/download_report`,
      {
        responseType: "blob", // Set the response type to blob to handle binary data
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // Create a blob object from the response data
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a temporary URL for the blob
    const url = window.URL.createObjectURL(blob);

    // Create a temporary link element
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "generated_file.xlsx"); // Set the desired file name

    // Simulate a click to trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up resources
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    // Handle error
    console.error("Error generating Excel file:", error);
  }
};

export const getReportFiltered = async (token, body) => {
  let queryParams = new URLSearchParams();

  Object.entries(body).forEach(([key, value]) => {
    if (typeof value === "string" && value.includes(",")) {
      // If the value is a string and contains commas, split it into an array
      value.split(",").forEach((item) => {
        if (item) queryParams.append(key, item.trim());
      });
    } else if (Array.isArray(value)) {
      // If the value is an array, add each item as a separate parameter
      value.forEach((item) => {
        if (item) queryParams.append(key, item);
      });
    } else if (value) {
      // If the value is not an array and is not empty, add it as a parameter
      queryParams.append(key, value);
    }
  });

  // Construct query string
  const queryString = queryParams.toString();

  console.log(queryString);
  return await axios.get(
    `${process.env.REACT_APP_API}/download_report_filtered?${queryString}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
