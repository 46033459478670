import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'


// Componentes
import OperatorNav from '../../components/navs/OperatorNav'
import Loading from '../../components/others/loading'
import MovimientosTable from '../../components/tables/MovimientosTable'

// Funciones
import { getMovimientos, updateMovimientos, getMovimientoById } from '../../functions/api/movimientos'
import { getAlmacenes } from '../../functions/api/almacenes'
import { getChoferes } from '../../functions/api/choferes'
import { getClientes } from '../../functions/api/clientes'
import { getEstados } from '../../functions/api/estados'
import { getTipoContenedor } from '../../functions/api/tipoContenedor'
import { getFlota } from '../../functions/api/flota'
import { getSubClientes } from '../../functions/api/subclientes'

const initialState = {
  "movimiento_id": null,
  "user_id": null,
  "new_numero_contenedor": "",
  "new_numero_sello": "",
  "new_numero_guia": "",
  "new_activo": true,
  "new_chofer_id_entrada": null,
  "new_chofer_id_salida": null,
  "new_cliente_id": null,
  "new_tipo_contenedor_id": null,
  "new_estado": "",
  "new_almacen_id": null,
  "new_camion_id_entrada": null,
  "new_camion_id_salida": null,
  "new_refeer": false,
  "new_conexion": "",
  "new_carga_peligrosa": "",
  "new_subcliente_id":null
}


const ResumenMovimiento = () => {

  const [values, setValues] = useState([])
  const [movimientos, setMovimientos] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [modal, setModal] = useState(false)
  const [perPage, setPerPage] = useState(100)  
  const [almacenes, setAlmacenes] = useState([])
  const [choferes, setChoferes] = useState([])
  const [clientes, setClientes] = useState([])
  const [subclientes, setSubClientes] = useState([])
  const [estados, setEstados] = useState([])
  const [flota, setFlota] = useState([])
  const [tipoContenedor, setTipoContenedor] = useState([])
  const [isSelected, setIsSelected] = useState(false);
  const id = useRef()

  let { user } = useSelector((state) => ({ ...state }))

  let navigate = useNavigate()

  useEffect(() => {

    loadMovimientos()
    loadAlmacenes()
    loadChoferes()
    loadClientes()
    loadTipoContenedor()
    loadFlota()
    loadSubClientes()

  }, []);


  const loadMovimientos = () => {
    setLoading(true)
    getMovimientos(user.token, page, perPage)
      .then(res => {
        setValues(res.data.data.filter(d => d.activo === true))
        setLoading(false)
      })
      .catch(err => {

        console.log(err)
        setLoading(false)
      })
  }

  const loadSubClientes = () => {
    setLoading(true)
    getSubClientes( user.token, page, perPage)
    .then( res => {
      setSubClientes(res.data.filter(d => d.activo === true))
      setLoading(false)
    })
    .catch( err => {
      console.log(err)
      setLoading(false)
    })
  }

  const loadAlmacenes = () =>{
    
    setLoading(true);
     getAlmacenes(user.token, page, perPage)
     .then(res => {
      setAlmacenes(res.data.filter(d => d.activo === true))
      setLoading(false);
    })
     .catch(err => {
      console.log(err)
      setLoading(false);
    })}
    
    const loadChoferes = () => {
      setLoading(true);
      getChoferes(user.token, page, perPage)
        .then((res) => {
          setChoferes(res.data.filter(d => d.activo === true));
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    };
    
    const loadClientes = () => {
      setLoading(true);
      getClientes(user.token, page, perPage)
        .then((res) => {
          setClientes(res.data.filter(d => d.activo === true));
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    };
  
    
    const loadTipoContenedor = () => {
      setLoading(true);
      getTipoContenedor(user.token, page, perPage)
        .then((res) => {
          setTipoContenedor(res.data.filter(d => d.activo === true));
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    };
    
    const loadFlota = () => {
      setLoading(true);
      getFlota(user.token, page, perPage)
        .then((res) => {
          setFlota(res.data.filter(d => d.activo === true));
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    };


  const updateMovimiento = (e) => {
    console.log(e)
  }

  

  return (
    loading ? (
      <Loading />
    ) : (
      <div >
        <OperatorNav />
        <div className="m-5">
          <MovimientosTable
            loading={loading}
            setValuesUpdate={setMovimientos}
            getMovimientoById={getMovimientoById}
            values={values}
            initialState={initialState}
            token={user.token}
            almacenes={almacenes}
            subclientes={subclientes}
            choferes={choferes}
            clientes={clientes}
            estados={estados}
            tipoContenedor={tipoContenedor}
            flota={flota}
            setLoading={setLoading}
            isSelected={isSelected}
            setIsSelected={setIsSelected}
            updateMovimiento={updateMovimiento}
          />

        </div>
      </div>
    ))
}

export default ResumenMovimiento