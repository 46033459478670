import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

// Componentes
import AdminNav from '../../../components/navs/AdminNav'
import TipoContenedorTable from '../../../components/tables/TipoContenedorTable'
import TipoContenedorCreate from './TipoContenedorCreate'
import TipoContenedorDetail from './TipoContenedorDetail'
import Loading from '../../../components/others/loading'

// Funciones
import { getTipoContenedor, createTipoContenedor, updateTipoContenedor } from '../../../functions/api/tipoContenedor'


const initialState = {
  "dimension": "",
  "descripcion": "",
  "refeer": true,
  "conexion": "",
  "carga_peligrosa": "",
  "activo": true
}


const filterInitialState = {
  "nombre_completo": "",
  "email": "",
  "contrasena": "",
  "role": ""
}


const TipoContenedoresList = () => {

  const [valuesUpdate, setValuesUpdate] = useState([])
  const [values, setValues] = useState(initialState)
  const [tipoContenedores, setTipoContenedores] = useState([])
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(100)
  const [isChecked, setIsChecked] = useState(false);
  const [filters, setFilters] = useState(filterInitialState)

  const id = useRef()

  let { user } = useSelector((state) => ({ ...state }))

  useEffect(() => {

    loadTipocontenedores()

  }, []);

  const loadTipocontenedores = () => getTipoContenedor(user.token, page, perPage).then(res => setTipoContenedores(res.data)).catch(err => console.log(err))


  const handleModal = (e) => {
    e.preventDefault(e)
    id.current = 1
    if (modal === false) {
      setLoading(true)
      id.current = e.target.value
      let filteredData = tipoContenedores.filter(d => d.id === Number(id.current));
      setValuesUpdate(filteredData[0])
      setLoading(false)
    } else {
      setValuesUpdate([])
    }
    setModal(!modal)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    setLoading(true)
    if (values.dimension === '') {
      toast.error('Ingrese dimensión')
      setLoading(false)
      // eslint-disable-next-line no-lone-blocks
    } {
      createTipoContenedor(user.token, values)
        .then(res => {
          toast.success('Tipo Contenedor creado exitosamente')
          loadTipocontenedores()
          console.log(res)
          setValues(initialState)
          setLoading(false)
        })
        .catch(err => {
          toast.error(err.response.data.detail)
          setLoading(false)
        })
    }

  }

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const handleChangeUpdate = (e) => {
    setValuesUpdate({ ...valuesUpdate, [e.target.name]: e.target.value })
  }

  const handleUpdate = (e) => {
    e.preventDefault()


    let data = {}
    data.dimension = valuesUpdate.dimension
    data.descripcion = valuesUpdate.descripcion
    data.activo = true

    setLoading(true)
    updateTipoContenedor(user.token, Number(id.current), data)
      .then(res => {
        toast.success('Tipo Contenedor actualizado exitosamente')
        loadTipocontenedores()
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
        setLoading(false)

      })

    setValuesUpdate([])
    setModal(!modal)

  }


  const data = tipoContenedores &&
    tipoContenedores
      .filter(d => d.activo === true)
  // .filter(d => d.nombre_completo.toUpperCase().includes(filters.nombre_completo.toUpperCase()))

  const handleDelete = (e) => {
    e.preventDefault()


    let data = {}
    data.dimension = valuesUpdate.dimension
    data.descripcion = valuesUpdate.descripcion
    data.activo = false

    setLoading(true)
    updateTipoContenedor(user.token, Number(id.current), data)
      .then(res => {
        toast.success('Tipo Contenedor actualizado exitosamente')
        loadTipocontenedores()
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
        setLoading(false)

      })

    setValuesUpdate([])
    setModal(!modal)

  }

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        modal && valuesUpdate && valuesUpdate.length !== 0 && (
          <TipoContenedorDetail
            handleModal={handleModal}
            valuesUpdate={valuesUpdate}
            handleChangeUpdate={handleChangeUpdate}
            handleUpdate={handleUpdate}
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            handleDelete={handleDelete}
          />
        ))}
      <AdminNav />
      <div className="subNav">
        <span>Admin / Tipo Contenedor</span>
      </div>
      <div className='contenedorTm'>
        <div className="form-container">
          <TipoContenedorCreate
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            values={values}
            isChecked={isChecked}
            setIsChecked={setIsChecked}
          />
        </div>
        <div className="table-title">
          <h6 >Tipo Contenedores</h6>
        </div>
        <div className="table-container">
          <TipoContenedorTable
            values={data}
            handleModal={handleModal}
          />
        </div>
      </div>
    </div>
  )
}

export default TipoContenedoresList