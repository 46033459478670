import React from 'react'


import DataTable from 'react-data-table-component'



const AlmacenesTable = ({values,handleModal}) => {


    const columns = [
        {
          name: 'ID',
          selector: row => row.id,
          width:'60px'
        },
        {
            name: 'Codigo',
            selector: row => row.codigo
        },
        {
            name: 'Nombre',
            selector: row => row.nombre
        },
        {
            name: 'Dirección',
            selector: row => row.direccion
        },
        {
            name: 'Detalle',
            selector: row => row.detalle
        }
    ]


    const data = values && values.map( c => {
        return {
            id:c.id,
            codigo:c.codigo,
            nombre:c.nombre,
            direccion: c.direccion,
            detalle: <button onClick={handleModal} value={c.id} className='btn btn-primary'> <i class="fa-solid fa-magnifying-glass"></i> Detalle</button>
            
        }
    })

  return (
    <DataTable
    data={data}
    columns={columns}
    pagination
    />
  )
}

export default AlmacenesTable