import React, { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom'
import { ChevronDown } from '../../img/ChevronArrow.jsx';
import { Navbar, NavbarBrand, NavbarMenuToggle,Image, NavbarMenu, NavbarMenuItem, NavbarContent, NavbarItem,  Button, DropdownItem, DropdownTrigger, Dropdown, DropdownMenu } from "@nextui-org/react";


const OperatorNav = () => {


  let navigate = useNavigate()

  let { user } = useSelector((state) => ({ ...state }))



  const dispatch = useDispatch()

  const logout = () => {
    dispatch({
      type: 'LOGOUT',
      payload: null
    })
    navigate('/')
  }



  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuItems = [
    {
      title:'Dashboard',
      to:'/operador/dashboard'
    }
  ]

  const dropDownMenuItems = [
    {
      title: 'Registro Movimientos',
      to: '/operador/registro-movimientos',
      description:'Visualizar movimientos de entrada y salida de carga'
    },
    {
      title: 'Ingresar Movimiento',
      to: '/operador/ingresar-movimiento',
      description:'Formulario ingreso movimientos'
    },
    {
      title: 'Stock',
      to: '/operador/stock',
      description:'Contenedores en Stock'
    }
  ];


  return (
    <Navbar onMenuOpenChange={setIsMenuOpen}>
      <NavbarContent>
        <NavbarMenuToggle
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          className="sm:hidden"
        />
        <NavbarBrand>
                <Image
                    alt="nextui logo"
                    height={40}
                    radius="sm"
                    src="https://avatars.githubusercontent.com/u/86160567?s=200&v=4"
                    width={40}
                />
          <p className="font-bold text-inherit ml-1">Transportes Muñoz</p>
        </NavbarBrand>
      </NavbarContent>
      <NavbarContent className="hidden sm:flex gap-4 " justify="center">

        <Dropdown>
          <NavbarItem>
            <DropdownTrigger>
              <Button
                disableRipple
                className="p-0 bg-transparent data-[hover=true]:bg-transparent"
                endContent={<ChevronDown fill="currentColor" size={16} />}
                radius="sm"
                variant="light"
              >
                MOVIMIENTOS
              </Button>
            </DropdownTrigger>
          </NavbarItem>
          <DropdownMenu
            aria-label="ACME features"
            className="w-[340px]"
            itemClasses={{
              base: "gap-4",
            }}
          >
            {dropDownMenuItems && dropDownMenuItems.map(c => (
              <DropdownItem
              onClick={() => navigate(c.to)}

                description={c.description}
              >
                <Link className="hover:no-underline text-slate-800" to={c.to} color="foreground " >
                  {c.title}
                </Link>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
        
      </NavbarContent>
      <NavbarContent justify="end">
        <NavbarItem>
          <Button onClick={logout} className='bg-slate-700 text-white hover:bg-slate-500 transition-all' href="#" variant="flat">
            Desconectarse
          </Button>
        </NavbarItem>
      </NavbarContent>
      <NavbarMenu>




        {dropDownMenuItems.map((c, index) => (
          <NavbarMenuItem key={`${c}-${index}`}>
            <Link
              color={
                index === 2 ? "primary" : index === dropDownMenuItems.length - 1 ? "danger" : "foreground"
              }
              className="w-full"
              to={c.to}
              size="lg"
            >
              {c.title}
            </Link>
          </NavbarMenuItem>
        ))}
      </NavbarMenu>
    </Navbar>

  )
}

export default OperatorNav
