import axios from 'axios'

export const getEstados = async(token,page,per_page) =>{
    return await axios.get(`${process.env.REACT_APP_API}/users/estados?page=${page}&per_page=${per_page}`,{
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
}


export const createEstado = async(token,body) =>{
    return await axios.post(`${process.env.REACT_APP_API}/users/create_estado`,body,{
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
}



export const updateEstado = async(token,body) =>{
    return await axios.put(`${process.env.REACT_APP_API}/users/update_estado`,body,{
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
}
