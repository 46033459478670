import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Input, Select, SelectItem } from "@nextui-org/react";
import { createChofer } from '../../functions/api/choferes';
import { getChoferes } from '../../functions/api/choferes';
import { toast } from 'react-toastify';
import { formatRut } from '../../functions/utilities/format';


const initialState = {
    "nombre_completo": "",
    "rut": "",
    "tipo_licencia": "",
    "vencimiento_licencia": "",
    "fecha_nacimiento": "",
    "activo": true
}


const ModalConductor = ({ isOpen, onOpenChange, setModalCamion, loadFlota }) => {


    const [values, setValues] = useState(initialState)
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(100)


    let { user } = useSelector((state) => ({ ...state }))



    const { nombre_completo, rut, tipo_licencia, vencimiento_licencia, fecha_nacimiento } = values

    const handleSubmit = (e) => {
        e.preventDefault()

        setLoading(true)
        e.preventDefault()

        setLoading(true)

        if (values.rut === '' || values.nombre_completo === '') {
            toast.error('Ingrese rut y nombre de conductor')
            setLoading(false)
        } else {
            createChofer(user.token, values)
                .then(res => {
                    toast.success('Conductor creado exitosamente')
                    setValues(initialState)
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    toast.error(err.response.data.detail)
                })
        }

    }

    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
    }

    const tipoLicenciaData =  [
        {
            id:1,
            licencia:'Licencia A5'
        },
        {
            id:2,
            licencia:'Ley antigua 18.290'
        }
    ]

    return (
        <Modal size='2xl' isOpen={isOpen} onOpenChange={onOpenChange} >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">CREAR CONDUCTOR</ModalHeader>
                        <ModalBody className='grid grid-cols-2'>

                            <Input
                                type="text"
                                label="RUT"
                                labelPlacement='outside'
                                placeholder="Ingrese RUT conductor"
                                value={formatRut(rut)}
                                variant='bordered'
                                className='uppercase'
                                name='rut'
                                onChange={handleChange}
                            />


                            <Input
                                type="text"
                                label="Nombre Completo"
                                labelPlacement='outside'
                                placeholder="Ingrese Nombre conductor"
                                value={nombre_completo}
                                variant='bordered'
                                className='uppercase'
                                name='nombre_completo'
                                onChange={handleChange}
                            />
                            <Select
                                items={tipoLicenciaData}
                                label="Tipo de licencia"
                                labelPlacement='outside'
                                placeholder="Seleccionar tipo de licencia"
                                variant='bordered'
                                value={tipo_licencia}
                                name='tipo_licencia'
                                onChange={handleChange}
                            >
                                {(tipoLicenciaData) => <SelectItem key={tipoLicenciaData.id}>{tipoLicenciaData.licencia}</SelectItem>}
                            </Select>
                            <Input
                                type="date"
                                label="Fecha vencimiento licencia"
                                labelPlacement='outside'
                                placeholder="Ingrese fecha vvencimiento"
                                value={vencimiento_licencia}
                                variant='bordered'
                                className='uppercase'
                                name='vencimiento_licencia'
                                onChange={handleChange}
                            />



                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" variant="light" onPress={onOpenChange}>
                                Cerrar
                            </Button>
                            <Button color="primary" onClick={handleSubmit}>
                                Guardar
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    )
}

export default ModalConductor