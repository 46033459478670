import React from 'react'

const Notfound = () => {
    return (
        <div className='flex flex-col justify-center items-center h-screen'>
            <h1 className='text-4xl '>404 Not Found</h1>
                <div className='bg-black h-1 w-20 mt-2'></div>
        </div>
    )
}

export default Notfound