import React from 'react'

const TipoContenedorCreate = ({ handleSubmit, handleChange, values, isChecked, setIsChecked }) => {

    const { dimension, descripcion } = values

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    return (
        <>
            <div className="row">
                <div className="col-md-10">
                    <p className='modalTitle'>CREAR TIPO CONTENEDOR</p>
                </div>

                <div className="col-md-5 mt-2 ">
                    <label className='label-title'>Dimensión</label>
                    <br />
                    <input className='loginInput' value={dimension} name='dimension' onChange={handleChange} />

                </div>
                <div className="col-md-7 mt-2 ">
                    <label className='label-title'>Descripción</label>
                    <br />
                    <input className='loginInput' value={descripcion} name='descripcion' onChange={handleChange} />

                </div>
                <div className='col-md-12 mt-3'>
                    <button onClick={handleSubmit} className='btn-blue'> <i class="fa-regular fa-floppy-disk"></i> Guardar</button>
                </div>
            </div>
        </>
    )
}

export default TipoContenedorCreate