import React from 'react'
import AdminNav from '../../components/navs/AdminNav'

const Home = () => {



  
  return (
    <div>
    <AdminNav/>
    
    <div className="subNav">
        <span> Admin / Home</span>
    </div>

    <div className='contenedorTm'>
      Home
    </div>
    </div>
  )
}


export default Home