import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

// Componentes
import AdminNav from '../../../components/navs/AdminNav'
import UsersTable from '../../../components/tables/UsersTable'
import UserDetail from './UserDetail'
import UserCreate from './UserCreate'
import Loading from '../../../components/others/loading'

// Funciones
import { getUserById, getUsers, updateUser, createUser } from '../../../functions/api/auth'



const initialState = {
    "nombre_completo": "",
    "email": "",
    "contrasena": "",
    "role": "",
    "activo": true
}


const filterInitialState = {
    "nombre_completo": "",
    "email": "",
    "contrasena": "",
    "role": ""
}

const UsersList = () => {

    const [values, setValues] = useState(initialState)
    const [valuesUpdate, setValuesUpdate] = useState([])
    const [userDetail, setUserDetail] = useState(false)
    const [isSelected, setIsSelected] = useState(true);
    const [modal, setModal] = useState(false)
    const [users, setUsers] = useState([])
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(100)
    const [loading, setLoading] = useState(false)
    const [filters, setFilters] = useState(filterInitialState)
    const id = useRef()

    let { user } = useSelector((state) => ({ ...state }))

    useEffect(() => {

        loadUsers()

    }, []);

    const loadUsers = () => getUsers(user.token, page, perPage).then(res => setUsers(res.data.data)).catch(err => console.log(err))

    const handleChange = (e) => {
        e.preventDefault(e)
        setValues({ ...values, [e.target.name]: e.target.value })
    }

    const handleChangeUpdate = (e) => {
        e.preventDefault(e)
        setValuesUpdate({ ...valuesUpdate, [e.target.name]: e.target.value })
    }


    const handleSubmit = (e) => {

        values.email = values.email.toLowerCase()

        setLoading(true)
        if (values.email === '') {
            toast.error('Email requerido')
            setLoading(false)
        } else if (values.contrasena === '') {
            toast.error('Contraseña requerida')
            setLoading(false)
        } else if (values.role === '') {
            toast.error('Rol requerido')
            setLoading(false)
        }
        else {
            createUser(user.token, values)
                .then(res => {
                    toast.success('Usuario creado exitosamente')
                    loadUsers()
                    setValues(initialState)
                    setLoading(false)
                })
                .catch(err => {
                    toast.error(err.response.data.detail)
                    setLoading(false)
                })
        }

    }




    const handleUpdate = (e) => {
        let data = {}

        data.nombre_completo = valuesUpdate.nombre_completo
        data.email = valuesUpdate.email
        data.contrasena = valuesUpdate.contrasena
        data.role = valuesUpdate.role
        data.activo = isSelected
        data.update_password = true

        setLoading(true)
        if (data.email === '') {
            toast.error('Email requerido')
            setLoading(false)
        } else if (data.contrasena === '') {
            toast.error('Contraseña requerida')
            setLoading(false)
        } else if (data.role === '') {
            toast.error('Rol requerido')
            setLoading(false)
        } else {
            updateUser(user.token, Number(id.current), data)
                .then(res => {
                    loadUsers()
                    setModal(!modal)
                    toast.success('Usuario actualizado con exito')
                    setLoading(false)
                })
                .catch(err => {
                    toast.error(err.response.data.detail)

                    setLoading(false)
                })

        }
    }


    const data = users &&
        users
            .filter(d => d.activo === true)
            .filter(d => d.nombre_completo.toUpperCase().includes(filters.nombre_completo.toUpperCase()))


    const handleFilterChange = (e) => {
        setFilters({ ...filters, [e.target.name]: e.target.value })
    }

    const handleModal = (e) => {
        e.preventDefault(e)
        id.current = 1
        if (modal === false) {
            setLoading(true)
            id.current = e.target.value
            let userData = data.filter(d => d.id === Number(id.current))
            setValuesUpdate(userData[0])
            setLoading(false)
        }

        setModal(!modal)
    }

    const handleDelete = () => {
        let data = {}

        data.nombre_completo = valuesUpdate.nombre_completo
        data.email = valuesUpdate.email
        data.contrasena = valuesUpdate.contrasena
        data.role = valuesUpdate.role
        data.activo = false
        data.update_password = true

        setLoading(true)
        if (data.email === '') {
            toast.error('Email requerido')
            setLoading(false)
        } else if (data.contrasena === '') {
            toast.error('Contraseña requerida')
            setLoading(false)
        } else if (data.role === '') {
            toast.error('Rol requerido')
            setLoading(false)
        } else {
            updateUser(user.token, Number(id.current), data)
                .then(res => {
                    loadUsers()
                    setModal(!modal)
                    toast.success('Usuario actualizado con exito')
                    setLoading(false)
                })
                .catch(err => {
                    toast.error(err.response.data.detail)

                    setLoading(false)
                })

        }
    }

    return (
        <div>
            {loading ? (
                <Loading />
            ) : (
                modal && valuesUpdate && valuesUpdate.length !== 0 && (
                    <UserDetail
                        handleModal={handleModal}
                        valuesUpdate={valuesUpdate}
                        handleChangeUpdate={handleChangeUpdate}
                        handleUpdate={handleUpdate}
                        handleDelete={handleDelete}
                    />
                ))}



            <AdminNav />
            <div className="subNav">
                <span>Admin / Usuarios</span>

            </div>
            <div className='contenedorTm'>
                <div className="form-container">
                    <UserCreate
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        values={values}
                        setIsSelected={setIsSelected}
                        isSelected={isSelected}
                    />
                </div>
                <div className="table-title">
                    <h6 >USUARIOS</h6>
                </div>
                <div className="table-container">
                    <div className='grid grid-cols-6 gap-6 p-1'>

                        <input
                            value={filters.nombre_completo}
                            name='nombre_completo'
                            onChange={handleFilterChange}
                            type='text'
                            placeholder='Filtrar por nombre usuario'
                            className='p-1 rounded-full'
                        />
                        <input
                            value={filters.email}
                            name='email'
                            onChange={handleFilterChange}
                            type='text'
                            placeholder='Filtrar por email'
                            className='p-1 rounded-full'
                        />
                    </div>
                    <UsersTable
                        values={data}
                        handleModal={handleModal}
                    />
                </div>
            </div>
        </div>
    )
}

export default UsersList







