import React from "react";

import DataTable from "react-data-table-component";

const ChoferesTable = ({ conductores, values, handleModal }) => {
  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      width: "60px",
    },
    {
      name: "Patente",
      selector: (row) => row.placa_patente,
    },
    {
      name: "Conductor",
      selector: (row) => row.chofer_id,
    },
    {
      name: "Detalle",
      selector: (row) => row.detalle,
    },
  ];

  const data =
    values &&
    values
      .filter((c) => c.activo === true)
      .map((c) => {
        return {
          id: c.id,
          placa_patente: c.placa_patente,
          chofer_id:
            conductores &&
            conductores
              .filter((d) => d.id === c.chofer_id)
              .map((f) => f.nombre_completo),
          detalle: (
            <button
              onClick={handleModal}
              value={c.id}
              className="btn btn-primary"
            >
              {" "}
              <i class="fa-solid fa-magnifying-glass"></i> Detalle
            </button>
          ),
        };
      });

  return <DataTable data={data} columns={columns} pagination />;
};

export default ChoferesTable;
