import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// Componentes
import AdminNav from "../../components/navs/AdminNav";
import Loading from "../../components/others/loading";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Divider,
  Input,
  Image,
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Select,
  SelectSection,
  SelectItem,
} from "@nextui-org/react";
import { getReport, getReportFiltered } from "../../functions/api/movimientos";
import {
  ArrowDownTrayIcon,
  AdjustmentsVerticalIcon,
} from "@heroicons/react/24/outline";
import { getClientes } from "../../functions/api/clientes";
import { getSubClientes } from "../../functions/api/subclientes";
import { getChoferes } from "../../functions/api/choferes";
import * as XLSX from "xlsx";

const filterState = {
  fecha_recepcion_start: null,
  fecha_recepcion_end: null,
  chofer_entrada: null,
  chofer_salida: null,
  cliente: null,
  subcliente: undefined,
};

const ReporteView = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filteredValues, setFilteredValues] = useState(filterState);
  const [clientes, setClientes] = useState([]);
  const [subclientes, setSubclientes] = useState([]);
  const [choferes, setChoferes] = useState([]);

  let { user } = useSelector((state) => ({ ...state }));

  const onSubmitExcel = async (token) => {
    getReport(user.token)
      .then((res) => {
        toast.success("Documento descargado exitosamente.");
      })
      .catch((err) => console.log(err));
  };

  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  useEffect(() => {
    loadClientes();
    loadSubclientes();
    loadChoferes();
  }, []);

  const loadFilteredValues = () => {
    getReportFiltered(user.token, filteredValues)
      .then((res) => {
        exportToExcel(res.data.data, "ReporteFiltrado");
      })
      .catch((err) => console.log(err));
  };

  const loadClientes = () => {
    getClientes(user.token, 1, 100)
      .then((res) => {
        setClientes(res.data.filter((d) => d.activo === true));
      })
      .catch((err) => console.log(err));
  };

  const loadSubclientes = () => {
    getSubClientes(user.token, 1, 100)
      .then((res) => {
        setSubclientes(res.data.filter((d) => d.activo === true));
      })
      .catch((err) => console.log(err));
  };

  const loadChoferes = () => {
    getChoferes(user.token, 1, 100)
      .then((res) => {
        setChoferes(res.data.filter((d) => d.activo === true));
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (e) => {
    setFilteredValues({ ...filteredValues, [e.target.name]: e.target.value });
  };

  const exportToExcel = (jsonData, fileName) => {
    console.log(jsonData);
    // Crear una nueva hoja de trabajo con los datos JSON
    const worksheet = XLSX.utils.json_to_sheet(jsonData);

    // Crear un nuevo libro de trabajo y añadir la hoja de trabajo
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Especificar el tipo de archivo y el nombre
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  const handleFilterSubmit = async () => {
    loadFilteredValues();
  };

  const {
    cliente,
    subcliente,
    chofer_entrada,
    chofer_salida,
    fecha_recepcion_end,
    fecha_recepcion_start,
  } = filteredValues;

  return (
    <div>
      <AdminNav />
      <Modal size="4xl" isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Filtrar reporte
              </ModalHeader>
              <ModalBody>
                <div className="grid grid-cols-2 gap-4">
                  <Select
                    items={clientes}
                    label="Seleccione cliente"
                    placeholder="Seleccionar Cliente"
                    size="sm"
                    onChange={handleChange}
                    selectionMode="multiple"
                    name="cliente"
                    value={cliente}
                    className="w-full"
                    variant="bordered"
                  >
                    {(item) => (
                      <SelectItem key={item.id}>{item.razon_social}</SelectItem>
                    )}
                  </Select>

                  <Select
                    items={subclientes.filter(
                      (d) => d.cliente_id === Number(cliente)
                    )}
                    label="Seleccione subcliente"
                    placeholder="Seleccionar Subcliente"
                    size="sm"
                    onChange={handleChange}
                    name="subcliente"
                    value={subcliente}
                    className="w-full"
                    variant="bordered"
                  >
                    {(item) => (
                      <SelectItem key={item.id}>{item.razon_social}</SelectItem>
                    )}
                  </Select>

                  <Select
                    items={choferes}
                    label="Seleccione Chofer Entrada"
                    placeholder="Seleccionar Chofer"
                    size="sm"
                    onChange={handleChange}
                    name="chofer_entrada"
                    value={chofer_entrada}
                    className="w-full"
                    variant="bordered"
                  >
                    {(item) => (
                      <SelectItem key={item.id}>
                        {item.nombre_completo}
                      </SelectItem>
                    )}
                  </Select>

                  <Select
                    items={choferes}
                    label="Seleccione Chofer Salida"
                    placeholder="Seleccionar Chofer"
                    size="sm"
                    onChange={handleChange}
                    name="chofer_salida"
                    value={chofer_salida}
                    className="w-full"
                    variant="bordered"
                  >
                    {(item) => (
                      <SelectItem key={item.id}>
                        {item.nombre_completo}
                      </SelectItem>
                    )}
                  </Select>

                  <Input
                    type="date"
                    label="Seleccione Fecha Inicio"
                    placeholder="Seleccionar Fecha "
                    size="sm"
                    onChange={handleChange}
                    name="fecha_recepcion_start"
                    value={fecha_recepcion_start}
                    className="w-full"
                    variant="bordered"
                  />

                  <Input
                    type="date"
                    label="Seleccione Fecha Termino"
                    placeholder="Seleccionar Fecha"
                    size="sm"
                    onChange={handleChange}
                    name="fecha_recepcion_end"
                    value={fecha_recepcion_end}
                    className="w-full"
                    variant="bordered"
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Cerrar
                </Button>
                <Button
                  radius="full"
                  className="w-full bg-gradient-to-tr from-purple-300 to-purple-500 text-white shadow-lg"
                  startContent={<ArrowDownTrayIcon className="h-6" />}
                  onPress={handleFilterSubmit}
                >
                  Descargar
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      <div className="m-5">
        <h1 className="text-2xl font-bold">Reportes</h1>
        <div className=" mt-3">
          <div className="">
            <Card className="max-w-[400px]">
              <CardHeader className="flex gap-3">
                <Image
                  alt="nextui logo"
                  height={40}
                  radius="sm"
                  src="https://avatars.githubusercontent.com/u/86160567?s=200&v=4"
                  width={40}
                />
                <div className="flex flex-col">
                  <p className="text-md">Resumen Movimientos</p>
                  <p className="text-small text-default-500">
                    Descarga documento Excel
                  </p>
                </div>
              </CardHeader>
              <Divider />
              {/* <CardBody>
                                <p>Reporte en Excel de movimientos de contenedores por cliente.</p>
                            </CardBody> */}
              <Divider />
              <CardFooter>
                <div className="grid grid-cols-2 justify-center text-center w-full gap-2">
                  <Button
                    onPress={onOpen}
                    radius="full"
                    className="w-full bg-gradient-to-tr from-purple-300 to-purple-500 text-white shadow-lg"
                    startContent={<AdjustmentsVerticalIcon className="h-6" />}
                  >
                    Filtrar reporte
                  </Button>
                  <Button
                    onClick={onSubmitExcel}
                    radius="full"
                    className="w-full bg-gradient-to-tr from-emerald-300 to-emerald-500 text-white shadow-lg"
                    startContent={<ArrowDownTrayIcon className="h-6" />}
                  >
                    Descargar reporte
                  </Button>
                </div>
              </CardFooter>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReporteView;
