import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'


// Componentes
import AdminNav from '../../components/navs/AdminNav'
import Loading from '../../components/others/loading'
import MovimientosRequestsTable from '../../components/tables/MovimientosRequestsTable'

// Funciones
import {  getMovimientosUpdateRequest } from '../../functions/api/movimientos'

const Aprobaciones = () => {

  const [loading, setLoading] = useState(false)

  let { user } = useSelector((state) => ({ ...state }))

  


  return (
    <div >
      <AdminNav />
      <div className="m-5">
        <MovimientosRequestsTable
          loading={loading}
          token={user.token}
          setLoading={setLoading}
          user={user}
        />
      </div>
    </div>
  )
}

export default Aprobaciones