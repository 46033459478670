import React from 'react'
import {
    Switch
} from "@nextui-org/react";

const UserDetail = ({ handleModal, valuesUpdate, handleChangeUpdate, handleUpdate, handleDelete }) => {


    const { nombre_completo, email, contrasena, role, activo } = valuesUpdate && valuesUpdate


    return (
        <>
            <div className='modalBackground' onClick={handleModal}>
            </div>
            <div className='modalCard'>
                <div className="row">
                    <div className="col-md-10">
                        <p className='modalTitle'>Detalles de usuario</p>
                    </div>

                    <div className="col-md-2">
                        <button onClick={handleDelete} className='btn btn-danger'>Eliminar Registro</button>
                    </div>
                    {/* {JSON.stringify(valuesUpdate)} */}
                    <div className="col-md-12 mt-2">
                        <hr className='bg-blue' />
                    </div>
                    <div className="col-md-6 mt-4">
                        <label className='label-title'>Nombre Completo</label>
                        <br />
                        <input className='loginInput' value={nombre_completo} name='nombre_completo' onChange={handleChangeUpdate} />
                        {/* <label className='label-value'>{nombre_completo}</label> */}
                    </div>
                    <div className="col-md-6 mt-4">
                        <label className='label-title'>Email</label>
                        <br />
                        <input className='loginInput' value={email} name='email' onChange={handleChangeUpdate} />
                    </div>
                    <div className="col-md-4 mt-4">
                        <label className='label-title'>Contraseña</label>
                        <br />
                        <input className='loginInput' type='password' value={contrasena} name='contrasena' onChange={handleChangeUpdate} />
                    </div>
                    <div className="col-md-4 mt-4">
                        <label className='label-title'>Rol</label>
                        <br />
                        <select className='loginInput' name="role" value={role} onChange={handleChangeUpdate}>
                            <option value='admin'>Admin</option>
                            <option value='operator'>Operador</option>
                            <option value='user'>User</option>
                        </select>
                    </div>

                    <div className='col-md-12 mt-5'>
                        <button onClick={handleUpdate} className='btn-blue'>Editar</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserDetail