import axios from "axios";




export const getFlota = async(token,page,per_page) =>{
    return await axios.get(`${process.env.REACT_APP_API}/users/camiones?page=${page}&per_page=${per_page}`,{
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
}



export const createFlota = async(token,body) =>{
    return await axios.post(`${process.env.REACT_APP_API}/users/create_camion`,body,{
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
}


export const updateFlota = async(token,id,body) =>{
    return await axios.put(`${process.env.REACT_APP_API}/users/update_camion/${id}`,body,{
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
}